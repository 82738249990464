body {
	font-family: Merriweather, serif;
	color: #313233;
	font-size: 1rem;
	line-height: 1.65rem;
	font-weight: 400;
	background-color: #fff;
}

h1 {
	margin-top: 0px;
	margin-bottom: 0.5rem;
	font-family: Merriweather, serif;
	color: #075488;
	font-size: 2.5rem;
	line-height: 3.4rem;
	font-weight: 400;
}

h2 {
	margin-top: 1.5rem;
	margin-bottom: 1.25rem;
	font-family: Merriweather, serif;
	color: #075488;
	font-size: 1.8rem;
	line-height: 2.7rem;
	font-weight: 400;
}

h3 {
	margin-top: 1.3rem;
	margin-bottom: 1rem;
	font-family: 'Work Sans', sans-serif;
	color: #075488;
	font-size: 1.4rem;
	line-height: 2.2rem;
	font-weight: 500;
}

h4 {
	margin-top: 1.2rem;
	margin-bottom: 0.75rem;
	font-family: 'Work Sans', sans-serif;
	color: #075488;
	font-size: 1.25rem;
	line-height: 2rem;
	font-weight: 500;
}

h5 {
	margin-top: 1.2rem;
	margin-bottom: 0.75rem;
	font-family: 'Work Sans', sans-serif;
	color: #075488;
	font-size: 1.15rem;
	line-height: 1.85rem;
	font-weight: 500;
}

h6 {
	margin-top: 1.2rem;
	margin-bottom: 0.5rem;
	font-family: 'Work Sans', sans-serif;
	color: #075488;
	font-size: 1rem;
	line-height: 1.7rem;
	font-weight: 500;
	text-transform: uppercase;
}

p {
	margin-bottom: 1rem;
}

a {
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-decoration: underline;
}

ul {
	margin-top: 0px;
	margin-bottom: 10px;
	padding-left: 40px;
}

ol {
	margin-top: 0px;
	margin-bottom: 10px;
	padding-left: 40px;
}

blockquote {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 20px;
	border-left: 5px solid #b3d236;
	font-size: 1.15rem;
	line-height: 1.95rem;
}

.header-section {
	position: fixed;
	z-index: 1000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-right: 3rem;
	padding-left: 3rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	background-color: #fff;
	box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.5);
}

.button {
	display: inline-block;
	height: 40px;
	min-width: 100px;
	margin-top: 1rem;
	margin-right: 1.5rem;
	margin-bottom: 0.5rem;
	padding: 0.35rem 0.75rem;
	border: 2px solid #075488;
	background-color: #075488;
	box-shadow: -1px 4px 8px 0 rgba(0, 0, 0, 0.35);
	font-family: 'Work Sans', sans-serif;
	color: #fff;
	font-size: 1.05rem;
	line-height: 1.55rem;
	font-weight: 500;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
}

.button:hover {
	color: #fff;
	border-color: #05436d;
	background-color: #05436d;
	box-shadow: -2px 9px 15px 2px rgba(0, 0, 0, 0.2), 0 0 8px 0 rgba(0, 0, 0, 0.45);
}

.button:active {
	box-shadow: -1px 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.button.secondary {
	border: 2px solid #b3d236;
	background-color: #b3d236;
	color: #05436d;
	text-align: center;
}

.button.secondary:hover {
	border-color: #9fbb2d;
	background-color: #9fbb2d;
}

.button.primary-option {
	box-shadow: none;
}

.button.primary-option:hover {
	border-color: #075488;
	background-color: #fff;
	color: #075488;
}

.button.secondary-option {
	border: 2px solid #b3d236;
	background-color: #b3d236;
	box-shadow: none;
	color: #05436d;
	text-align: center;
}

.button.secondary-option:hover {
	background-color: #fff;
}

.field-label {
	font-family: 'Work Sans', sans-serif;
	font-weight: 500;
}

.text-link {
	padding-bottom: 2px;
	box-shadow: inset 0 -1px 0 0 #bbbdc0;
	font-family: Merriweather, serif;
	color: #467ba9;
	font-weight: 700;
	text-decoration: none;
}

.text-link:hover {
	box-shadow: inset 0 -2px 0 0 #83a9c3;
	color: #1f5390;
}

.text-link.right-column-link {
	font-size: 0.85rem;
}

.right-col-list {
	font-size: 0.9rem;
	line-height: 1.4rem;
}

.right-col-item-wrap {
	margin-bottom: 1rem;
	padding-bottom: 0.75rem;
	border-bottom: 1px solid #d6d2c6;
}

.intro-paragraph {
	display: block;
	margin-bottom: 1.2rem;
	color: #393f44;
	font-size: 1.25rem;
	line-height: 2rem;
	font-weight: 400;
}

.breadcrumbs-list-item {
	margin-top: 0.25rem;
	margin-right: 0.5rem;
	padding-right: 0.5rem;
	float: left;
	border-right: 1px solid #bdbdbd;
}

.breadcrumbs-list-item.current {
	padding-right: 0rem;
	border-right-style: none;
	color: #5d6469;
}

.breadcrumbs-list-item.current {
	padding-right: 0rem;
	border-right-style: none;
	color: #636363;
}

.right-col-list-with-bullets {
	padding-left: 20px;
	font-size: 0.85rem;
	line-height: 1.4rem;
}

.breadcrumbs-link {
	color: #465158;
	text-decoration: none;
}

.breadcrumbs-link:hover {
	box-shadow: inset 0 -1px 0 0 rgba(7, 84, 136, 0.5);
	color: #075488;
	font-weight: 400;
}

.block-quote-link {
	padding-bottom: 2px;
	box-shadow: inset 0 -1px 0 0 #bbbdc0;
	color: #467ba9;
	font-weight: 700;
	text-decoration: none;
}

.block-quote-link:hover {
	box-shadow: inset 0 -2px 0 0 #83a9c3;
}

.block-quote-link.w--current {
	border-bottom: 1px solid hsla(38, 77%, 77%, 0.5);
	color: #f2d199;
}

.block-quote-link.w--current:hover {
	border-bottom-color: transparent;
	color: #bf935e;
}

.screen-message-text {
	font-weight: 500;
}

.header-link {
	padding-bottom: 3px;
	box-shadow: inset 0 -1px 0 0 #bbbdc0;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #467ba9;
	text-decoration: none;
}

.header-link:hover {
	padding-bottom: 3px;
	box-shadow: inset 0 -3px 0 0 #83a9c3;
	color: #1f5390;
}

.breadcrumbs-list {
	display: block;
	max-width: 1400px;
	margin: 0.75rem auto 0rem;
	padding-left: 0px;
	font-family: 'Work Sans', sans-serif;
	color: #393f44;
	font-size: 0.8rem;
	line-height: 1.2rem;
	letter-spacing: 0.01rem;
}

.screen-message {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
}

.screen-message.error {
	border-left: 6px solid #c70616;
	background-color: rgba(253, 54, 54, 0.15);
	font-family: 'Work Sans', sans-serif;
	font-size: 1.1rem;
}

.screen-message.success {
	border-left: 6px solid #11b91e;
	background-color: rgba(17, 185, 30, 0.25);
	font-family: 'Work Sans', sans-serif;
	font-size: 1.1rem;
}

.right-side-col {
	width: 20%;
	max-width: 250px;
	min-width: 170px;
	margin-left: 3.25vw;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.right-side-col h2 {
	margin-bottom: 0.25rem;
	font-family: 'Work Sans', sans-serif;
	font-size: 1.15rem;
	line-height: 1.55rem;
	font-weight: 500;
	text-transform: uppercase;
}

.right-side-col h4 {
	margin-top: 1.5rem;
	margin-bottom: 0.25rem;
	font-size: 1.15rem;
	line-height: 1.55rem;
	text-transform: uppercase;
}

.right-side-col h3 {
	margin-top: 1.5rem;
	margin-bottom: 0.25rem;
	font-size: 1.15rem;
	line-height: 1.55rem;
	text-transform: uppercase;
}

.right-side-col p {
	margin-bottom: 0.5rem;
	font-size: 0.85rem;
	line-height: 1.4rem;
}

.button-styles {
	padding-top: 0rem;
	padding-bottom: 0rem;
}

.breadcrumbs-container {
	max-width: 1271px;
	padding-bottom: 1rem;
	padding-left: 0.125rem;
	font-size: 0.9rem;
	line-height: 1.5rem;
}

.main-content {
	display: block;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.style-tile-heading-less-margin {
	margin-top: 0rem;
	margin-bottom: 0.25rem;
}

.style-tile-heading-less-margin.kw3 {
	display: inline-block;
	float: right;
}

.style-tile-heading-less-margin.kw2 {
	display: block;
	padding-left: 0px;
	float: left;
	clear: left;
}

.style-tile-image-parent {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.style-tile-subhead {
	display: inline-block;
	margin-bottom: 1rem;
	font-size: 0.8rem;
	text-transform: uppercase;
}

.style-tile-section-wrap {
	width: 100%;
}

.style-tile-section-wrap.style-tile-half-wrap {
	width: 60%;
	padding-right: 1rem;
}

.style-tile-section-wrap.style-tile-half-wrap.keywords-hidden {
	display: none;
}

.style-tile-section-wrap.color-palette-primary {
	width: 40%;
	padding-right: 2rem;
}

.style-tile-section-wrap.color-palette-secondary {
	width: 60%;
	padding-left: 2rem;
	border-left: 1px solid #cecab7;
}

.style-tile-section-wrap.style-tile-imagery-wrap {
	width: 40%;
}

.style-tile-double-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.style-tile-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	max-width: 1280px;
	min-width: 1024px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 3rem;
	padding-bottom: 4rem;
	padding-left: 3rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-color: #fff;
	box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.3);
}

.style-tile-container.style-tile-header-container {
	padding-bottom: 0rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: transparent;
	box-shadow: none;
}

.upper {
	position: relative;
	z-index: 100;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: -webkit-linear-gradient(45deg, #f9b604, #ed9648), -webkit-linear-gradient(44deg, #9eceff, #004892 85%);
	background-image: linear-gradient(45deg, #f9b604, #ed9648), linear-gradient(46deg, #9eceff, #004892 85%);
}

.upper.style-tile {
	display: block;
	height: 38px;
	background-color: #075488;
	background-image: none;
}

.style-tile-section-head {
	display: inline-block;
	margin-bottom: 1.5rem;
	font-family: 'Work Sans', sans-serif;
	line-height: 1.5rem;
	font-weight: 500;
	text-transform: uppercase;
}

.style-tile-image-wrap {
	width: 200px;
	height: 200px;
	margin-right: 1rem;
	margin-bottom: 1rem;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.color-swatch-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.color-swatch {
	width: 100px;
	height: 100px;
	background-color: #313233;
}

.color-swatch._7 {
	width: 100px;
	height: 100px;
	background-color: #83a9c3;
}

.color-swatch._8 {
	width: 100px;
	height: 100px;
	background-color: #b3d236;
}

.color-swatch._2 {
	width: 100px;
	height: 100px;
	background-color: #b3d236;
}

.color-swatch._6 {
	width: 100px;
	height: 100px;
	background-color: #457fa6;
}

.color-swatch._1 {
	width: 100px;
	height: 100px;
	background-color: #075488;
}

.color-swatch._9 {
	width: 100px;
	height: 100px;
	background-color: #d9e89a;
}

.style-tile-header {
	float: left;
	font-family: 'Work Sans', sans-serif;
	color: #fff;
	line-height: 1.5rem;
	font-weight: 600;
	text-transform: uppercase;
}

.style-tile-button-wrap {
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.hr-divider {
	width: 100%;
	height: 2px;
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-bottom: 1px solid #cecab7;
}

.style-tile-logo {
	width: 100%;
}

.style-tile-h1-less-margin {
	margin-top: 0rem;
	margin-bottom: 1rem;
}

.style-tile-h1-less-margin.keyword {
	display: inline-block;
	float: left;
}

.style-tile-logo-wrap {
	width: 450px;
	margin-top: 40px;
}

.swatch-outer-wrap {
	margin-right: 2rem;
	text-align: center;
}

.block-quote-accent {
	position: absolute;
	left: -6px;
	width: 16px;
	height: 16px;
	background-color: #075488;
}

.block-quote-wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.style-tile-caption {
	font-size: 0.85rem;
	line-height: 1.5rem;
}

.left-arrow-two {
	left: auto;
	top: auto;
	bottom: 0px;
	z-index: 300;
	width: 5rem;
	height: 3.5rem;
	opacity: 0.6;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.left-arrow-two:hover {
	opacity: 1;
	-webkit-transform: translate(-8px, 0px);
	-ms-transform: translate(-8px, 0px);
	transform: translate(-8px, 0px);
}

.slide-nav-main {
	z-index: 300;
	width: 100%;
	max-width: 800px;
	font-size: 0.625rem;
}

.ken-burns-wrapper {
	position: absolute;
	left: -100px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	overflow: hidden;
	height: 100%;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.ken-burns-wrapper._1 {
	background-image: none;
}

.ken-burns-slide-1 {
	position: relative;
	overflow: hidden;
	-webkit-perspective-origin: 50% 50%;
	perspective-origin: 50% 50%;
}

.right-arrow-two {
	left: auto;
	top: auto;
	right: 0px;
	bottom: 0px;
	z-index: 300;
	width: 5rem;
	height: 3.5rem;
	opacity: 0.6;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.right-arrow-two:hover {
	opacity: 1;
	-webkit-transform: translate(8px, 0px);
	-ms-transform: translate(8px, 0px);
	transform: translate(8px, 0px);
}

.arrow-icon {
	margin-right: 1.5rem;
	margin-bottom: 1rem;
	margin-left: 2.25rem;
	font-size: 1.5rem;
}

.hero-rotator-widget-kburns {
	width: 100%;
	height: 72vh;
	background-color: #000;
	overflow: hidden;
}

.hero-rotator-section-kburns {
	position: relative;
	z-index: 2;
	width: 100%;
	padding-top: 128px;
	background-color: #fff;
}

.secondary-nav-text {
	padding: 0.5rem 0.5rem 0.4rem;
	float: left;
	font-weight: 600;
}

.secondary-nav-list {
	display: none;
	margin-top: 0em;
	margin-right: 6px;
	margin-bottom: 0rem;
	padding-left: 0em;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	font-size: 1rem;
	line-height: 1.25em;
}

.header-search-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	border-bottom: 1px solid #bbbdc0;
}

.button-2 {
	display: inline-block;
	margin-top: 0.5em;
	margin-right: 1rem;
	margin-bottom: 0.5em;
	padding: 0.5em 1.3em;
	background-color: #494948;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #fff;
	font-size: 0.9375rem;
	font-weight: 500;
	text-align: center;
	letter-spacing: 0.02em;
	text-decoration: none;
	text-transform: uppercase;
}

.button-2:hover {
	background-color: #2c2c2b;
	color: #c9c6c4;
}

.button-2.header-search-button {
	width: 2rem;
	height: 2rem;
	margin-top: 0em;
	margin-right: 0rem;
	margin-bottom: 0em;
	padding-top: 0em;
	padding-bottom: 0em;
	background-color: transparent;
	background-image: url('/20170713122722/assets/images/search_vdmc-blue.svg');
	background-position: 50% 50%;
	background-size: 20px 20px;
	background-repeat: no-repeat;
	color: transparent;
	font-size: 1rem;
	line-height: 1em;
	text-transform: uppercase;
}

.button-2.header-search-button:hover {
	background-image: url('/20170713122722/assets/images/search_graya1a4a8.svg');
	background-size: 20px 20px;
}

.ecomm-header-wrap {
	display: none;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.header-content-right-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.header-utility-wrap {
	margin-bottom: 0.5rem;
	padding-top: 1rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	height: 49px;
}

.form-field {
	position: relative;
	width: auto;
	margin-bottom: 0.75em;
	padding: 4px 10px;
	font-size: 0.875em;
	font-weight: 400;
}

.form-field.header-search-field {
	width: 170px;
	height: 2rem;
	margin-bottom: 0em;
	padding-left: 0px;
	border: 1px solid transparent;
	background-color: hsla(0, 0%, 100%, 0.8);
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	color: #000;
	font-size: 0.9em;
	line-height: 1.125em;
	font-weight: 400;
}

.form-field.header-search-field:focus {
	width: 220px;
	background-color: #fff;
}

.form-field.header-search-field::-webkit-input-placeholder {
	color: #4a4b4d;
	letter-spacing: -0.02rem;
}

.form-field.header-search-field:-ms-input-placeholder {
	color: #4a4b4d;
	letter-spacing: -0.02rem;
}

.form-field.header-search-field::placeholder {
	color: #4a4b4d;
	letter-spacing: -0.02rem;
}

.secondary-nav-list-item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0em;
	padding-right: 2px;
	padding-left: 2px;
	float: left;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	border-right: 1px none rgba(0, 0, 0, 0.15);
}

.secondary-nav-list-item.first {
	border-left-style: none;
}

.secondary-nav-list-link {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 0.5rem;
	float: none;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-family: 'Work Sans', sans-serif;
	color: #075488;
	font-size: 0.9rem;
	line-height: 1.125em;
	font-weight: 600;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
}

.secondary-nav-list-link:hover {
	color: #a1a4a8;
}

.ecomm-link-text {
	padding-top: 0.5rem;
	padding-right: 0.5rem;
	padding-bottom: 0.4rem;
	float: left;
	font-weight: 600;
}

.ecomm-header-link {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 1rem;
	padding-left: 0px;
	float: none;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: url('/20170713122722/assets/images/user_fa_6f6e6d.svg');
	background-position: 0px 50%;
	background-size: 20px 20px;
	background-repeat: no-repeat;
	color: #494948;
	font-size: 0.6875rem;
	line-height: 1.125em;
	font-weight: 600;
	text-align: center;
	text-transform: uppercase;
}

.ecomm-header-link:hover {
	background-image: url('/20170713122722/assets/images/user_fa_d91b0c.svg');
	background-size: 20px 20px;
	color: #d91b0c;
}

.ecomm-header-link.account-icon {
	width: 25px;
	height: 22px;
	margin-right: 1rem;
	background-image: url('/20170713122722/assets/images/user-fa-solid_vdmc-blue.svg');
	background-position: 50% 50%;
	background-size: 20px;
}

.ecomm-header-link.account-icon:hover {
	background-image: url('/20170713122722/assets/images/user-fa-solid_graya1a4a8.svg');
	background-size: 20px 20px;
}

.ecomm-header-link.cart-icon {
	padding-left: 24px;
	background-image: url('/20170713122722/assets/images/shopping-basket-vdmc-blue.svg');
	background-size: 22px 20px;
	font-family: 'Work Sans', sans-serif;
	color: #4a4b4d;
	font-size: 0.8rem;
}

.ecomm-header-link.cart-icon:hover {
	background-image: url('/20170713122722/assets/images/shopping-basket-graya1a4a8.svg');
	background-size: 22px 20px;
}

.header-search-wrap {
	margin-right: auto;
	margin-bottom: 0px;
	margin-left: auto;
	float: right;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	background-color: transparent;
	font-family: 'Work Sans', sans-serif;
}

.nav-drop-list {
	overflow: hidden;
}

.nav-drop-list.w--open {
	top: 88%;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	border-top: 1px solid #b3d236;
	background-color: #737373;
	box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.3);
	color: #fff;
}

.header-mobile-search {
	display: block;
	overflow: hidden;
	height: 0px;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
}

.nav-container {
	-webkit-transition: none 0ms ease;
	transition: none 0ms ease;
}

.nav-drop-wrap {
	position: relative;
	margin-right: 0.25rem;
	margin-left: 0.25rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	flex: 1;
}

.header-menu-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	background-color: transparent;
}

.div-block-4 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.logo-link {
	display: block;
	width: 400px;
	margin-right: 2rem;
	padding-left: 0px;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition-property: none;
	transition-property: none;
	color: #fff;
	font-size: 1.625rem;
	line-height: 1.25rem;
	font-weight: 600;
	text-align: center;
	cursor: pointer;
}

.logo-link:hover {
	color: #fff;
}

.logo-link.w--current {
	display: block;
}

.nav-drop-toggle {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: visible;
	width: 100%;
	padding: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
	box-shadow: 0 -3px 0 0 transparent;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-align: center;
}

.nav-drop-toggle.w--open {
	box-shadow: none;
}

.nav-section {
	background-color: transparent;
	height: 50px;
}

.main-nav-link {
	padding: 0.65rem 0.75vw 1.15rem;
	-webkit-transition-duration: 500ms;
	transition-duration: 500ms;
	font-family: 'Work Sans', sans-serif;
	color: #075488;
	font-size: 1.05rem;
	line-height: 1.33rem;
	font-weight: 500;
	text-align: center;
	letter-spacing: -0.02rem;
	text-decoration: none;
	text-transform: uppercase;
	display: inline-block;
	position: relative;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
	background-size: 12px 12px;
	background-position: center 38px;
	background-repeat: no-repeat;
	background-image: url("/assets/images/transparent.svg");
}

.main-nav-link:after,
.main-nav-link:after {
	border-bottom: 1px solid transparent;
	content: "";
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 5px;
	width: 100%;
	height: 1px;
	-webkit-transition: border-bottom-color 400ms ease;
	transition: border-bottom-color 400ms ease;
}

.main-nav-link.active:after,
.main-nav-link:hover:after {
	border-bottom-color: #b3d236;
}

.main-nav-link:hover {
	background-image: url("/20170713122722/assets/images/bg-drop-down.jpg");
}

.nav-drop-wrap.has-dropdown:hover .main-nav-link {
	background: none;
}

.nav-menu-wrap {
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	font-size: 1.1rem;
	line-height: 1.375rem;
	font-weight: 500;
	text-align: right;
}

.nav-drop-list-link {
	margin-bottom: 1px;
	padding: 0.65em 1rem;
	font-family: 'Work Sans', sans-serif;
	color: #075488;
	font-size: 1rem;
	line-height: 1.375em;
	font-weight: 400;
	letter-spacing: -0.02rem;
}

.nav-drop-list-link:hover {
	background-color: rgba(7, 84, 136, 0.2);
}

.nav-drop-list-link.w--current {
	background-color: #ffffff;
	color: #075488;
	cursor: pointer;
}

.nav-drop-list-link.last {
	margin-bottom: 0px;
}

.slide-color-overlay {
	position: relative;
	height: 100%;
	background-image: -webkit-linear-gradient(0deg, transparent, rgba(3, 52, 85, 0.7)), -webkit-linear-gradient(270deg, rgba(7, 84, 136, 0.51), rgba(7, 84, 136, 0.51));
	background-image: linear-gradient(90deg, transparent, rgba(3, 52, 85, 0.7)), linear-gradient(180deg, rgba(7, 84, 136, 0.51), rgba(7, 84, 136, 0.51));
}

.arrow-icon-right {
	margin-right: 2.25rem;
	margin-bottom: 1rem;
	margin-left: 1.5rem;
	font-size: 1.5rem;
}

.slide-caption-container {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 60px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-right: 6vw;
	padding-left: 6vw;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.slide-header {
	font-family: Merriweather, serif;
	color: #fff;
	font-size: 4rem;
	line-height: 5rem;
	font-weight: 400;
}

.slide-caption-wrapper {
	position: relative;
	top: 25vh;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 45%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	border-right: 2px none #b3d236;
	border-bottom: 2px none #b3d236;
	text-align: right;
}

.home-section.row-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	background-image: -webkit-linear-gradient(270deg, rgba(0, 152, 255, 0.05), rgba(0, 152, 255, 0.05)), url('/20170713122722/assets/images/p6_2X_1p6_@2X.png');
	background-image: linear-gradient(180deg, rgba(0, 152, 255, 0.05), rgba(0, 152, 255, 0.05)), url('/20170713122722/assets/images/p6_2X_1p6_@2X.png');
	background-position: 0px 0px, 0px 0px;
	background-size: auto, 8px;
}

.home-section.row-1 {
	border-bottom: 1px none #bbbdc0;
	background-color: #fff;
}

.home-section.row-3 {
	padding-top: 6rem;
	padding-bottom: 8rem;
	background-position: 0px 0px, 0px 0px, 50% 50%;
	background-size: auto, auto, cover;
	background-repeat: repeat, repeat, no-repeat;
	background-attachment: scroll, scroll, fixed;
}

.home-section.row-4 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 2rem 8vw;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background-color: #fff;
}

.home-section.footer {
	position: relative;
	background-color: #033455;
}

.container {
	display: block;
	max-width: 1270px;
	margin-right: auto;
	margin-left: auto;
}

.container.quicklink-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.container.footer-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: none;
	margin-right: 0px;
	margin-left: 0px;
	padding: 2rem 8vw;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.container.inside-page-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 1rem;
}

.container.inside-page-container.no-sidebars {
	max-width: 1020px;
}

.quicklink-link {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	flex-direction: column;
	width: 33.33%;
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: center;
	text-decoration: none;
}

.quicklink-link:hover {
	-webkit-transform: translate(0px, -6px);
	-ms-transform: translate(0px, -6px);
	transform: translate(0px, -6px);
}

.quicklink-link.ql-left-right:hover {
	background-color: transparent;
}

.quicklink-header {
	margin-top: 0px;
	margin-bottom: 0px;
	padding-right: 14px;
	background-image: url('/20170713122722/assets/images/arrow-vdmc-blue.svg');
	background-position: 100% 50%;
	background-size: 10px 10px;
	background-repeat: no-repeat;
	font-family: 'Work Sans', sans-serif;
	font-size: 1.2rem;
	line-height: 1.8rem;
	font-weight: 500;
	text-align: center;
	letter-spacing: -0.02rem;
	text-transform: uppercase;
}

.slide-paragraph {
	width: 100%;
	min-width: 450px;
	float: right;
	background-color: transparent;
	font-family: 'Work Sans', sans-serif;
	color: #fff;
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-weight: 300;
}

.quicklink-icon-wrap {
	width: 2.75rem;
	height: 2.75rem;
	margin-bottom: 0.5rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	background-color: rgba(179, 210, 54, 0.75);
	background-image: url('/20170713122722/assets/images/doctor-vdmc-blue.svg');
	background-position: 50% 50%;
	background-size: 28px 28px;
	background-repeat: no-repeat;
}

.quicklink-icon-wrap.providericon {
	background-image: url('/20170713122722/assets/images/doctor2-vdmc-blue.svg');
	background-size: 32px 32px;
}

.quicklink-icon-wrap.billpayicon {
	background-image: url('/20170713122722/assets/images/billpay2-vdmc-blue.svg');
	background-size: 30px 30px;
}

.quicklink-icon-wrap.portalicon {
	background-image: url('/20170713122722/assets/images/patient-portal-vdmc-blue.svg');
	background-size: 32px 32px;
}

.quicklink-icon-wrap.careersicon {
	background-image: url('/20170713122722/assets/images/careers.svg');
	background-size: 32px 32px;
}

.quicklink-icon-wrap.donateicon {
	background-image: url('/20170713122722/assets/images/donate.svg');
	background-size: 32px 32px;
}

.callouts-cb {
	position: relative;
	top: 4rem;
	z-index: 999;
	width: 40%;
	padding-right: 2vw;
	background-color: #fff;
	box-shadow: 0 7px 30px 0 rgba(0, 0, 0, 0.25);
}

.callout-item-wrap {
	margin-left: 2vw;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	padding-left: 5rem;
	border-bottom: 1px solid #bbbdc0;
	background-position: 0px 50%;
	background-size: 60px 60px;
	background-repeat: no-repeat;
}

.callout-item-wrap.last-callout {
	padding-bottom: 0.75rem;
	border-bottom-style: none;
}

.welcome-cb {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 60%;
	margin-left: 8vw;
	padding-top: 2rem;
	padding-right: 4vw;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.welcome-cb.full {
	width: 100%;
	padding-bottom: 4rem;
	padding-right: 4vw;
	padding-left: 4vw;
	max-width: 1400px;
	margin: 0 auto;
}

.testimonials-cb {
	display: block;
	width: 100%;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
	padding-top: 2rem;
	padding-right: 5rem;
	padding-left: 5rem;
}

.news-events-cb {
	width: 45%;
}

.slide-caption-inner-wrap {
	position: relative;
	width: 100%;
	margin-top: 0.75rem;
	padding-right: 3.5rem;
	padding-bottom: 2rem;
	border-right: 5px none #b3d236;
}

.slide-accent-block {
	position: absolute;
	right: -30px;
	bottom: -30px;
	width: 60px;
	height: 60px;
	background-color: hsla(0, 0%, 100%, 0.1);
}

.accent-gradient {
	width: 100%;
	height: 5px;
	background-image: -webkit-linear-gradient(0deg, transparent, #b3d236);
	background-image: linear-gradient(90deg, transparent, #b3d236);
}

.accent-gradient-vert {
	position: absolute;
	right: 0px;
	bottom: 0px;
	width: 5px;
	height: 100%;
	background-image: -webkit-linear-gradient(270deg, transparent, #b3d236);
	background-image: linear-gradient(180deg, transparent, #b3d236);
}

.nav-accent {
	position: absolute;
	top: 38px;
	bottom: 0px;
	z-index: 10000;
	width: 12px;
	height: 12px;
	background-color: #b3d236;
	opacity: 0;
}

.home-paragraph {
	display: block;
	margin-bottom: 1.2rem;
	color: #313233;
	font-size: 1.15rem;
	line-height: 2rem;
	font-weight: 400;
}

.home-subhead {
	margin-top: 1rem;
	margin-bottom: 0.25rem;
	font-weight: 500;
}

.accent-gradient-2 {
	width: 100%;
	height: 5px;
	background-image: -webkit-linear-gradient(0deg, #b3d236, transparent);
	background-image: linear-gradient(90deg, #b3d236, transparent);
}

.testimonial-arrow-icon {
	font-size: 1.25rem;
}

.testimonial-star-wrapper {
	display: inline-block;
	width: 20px;
	height: 20px;
	margin-right: 8px;
	margin-left: 8px;
	float: none;
	background-position: 50% 50%;
	background-size: 15px 15px;
	background-repeat: no-repeat;
}

.slider {
	height: 400px;
	background-color: hsla(0, 0%, 100%, 0.8);
	box-shadow: 0 7px 25px 0 rgba(0, 0, 0, 0.3);
}

.testimonial-author {
	margin-bottom: 0rem;
	text-align: center;
}

.testimonial-paragraph {
	font-size: 1.5rem;
	line-height: 2.4rem;
	font-weight: 400;
	text-align: center;
}

.testimonial-star-outer-wrap {
	position: relative;
	display: none;
	height: 22px;
	margin-bottom: 1.25rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	text-align: center;
}

.testimonial-slide-nav-dots {
	display: block;
	width: 300px;
	font-size: 0.6rem;
}

.testimon-slide {
	padding-top: 0rem;
	padding-bottom: 3rem;
	border-bottom-style: solid;
	border-bottom-width: 2px;
	background-image: none;
	border-color: #075488;
}

.testimonial-arrow-left {
	left: auto;
	top: auto;
	bottom: 0px;
	width: 50px;
	height: 50px;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: rgba(7, 84, 136, 0.4);
}

.testimonial-slide-nav-dots {
	padding-top: 0px;
}

.testimonial-slide-nav-dots .w-slider-dot {
	width: 17px;
	height: 17px;
}

.testimonial-arrow-left:hover {
	-webkit-transform: translate(-8px, 0px);
	-ms-transform: translate(-8px, 0px);
	transform: translate(-8px, 0px);
	color: #075488;
}

.testimonial-text-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	height: 100%;
	padding-top: 1.5rem;
	padding-right: 4rem;
	padding-left: 4rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.quote-wrapper {
	display: block;
	width: 60px;
	height: 60px;
	margin-right: auto;
	margin-bottom: 1rem;
	margin-left: auto;
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

.testimonial-arrow-right {
	left: auto;
	top: auto;
	right: 0px;
	bottom: 0px;
	width: 50px;
	height: 50px;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: rgba(7, 84, 136, 0.4);
}

.testimonial-arrow-right:hover {
	-webkit-transform: translate(8px, 0px);
	-ms-transform: translate(8px, 0px);
	transform: translate(8px, 0px);
	color: #075488;
}

.news-content-wrap {
	width: 100%;
	margin-bottom: 1.5rem;
}

.news-feed-title {
	display: block;
	margin-top: 0rem;
	margin-bottom: 0.25rem;
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-weight: 400;
	letter-spacing: 0rem;
	text-transform: none;
}

.news-events-title-link {
	padding-bottom: 2px;
	box-shadow: inset 0 -1px 0 0 #bbbdc0;
	font-family: Merriweather, serif;
	color: #467ba9;
	font-weight: 700;
	text-decoration: none;
}

.news-events-title-link:hover {
	border-bottom-color: transparent;
	box-shadow: inset 0 -2px 0 0 #83a9c3;
	color: #274c77;
}

.news-events-title-link.w--current {
	color: #1f656e;
	cursor: pointer;
}

.news-date {
	display: inline-block;
	margin-bottom: 0.5rem;
	font-family: 'Work Sans', sans-serif;
	font-weight: 500;
	text-transform: uppercase;
}

.news-event-item {
	position: relative;
	z-index: 1;
	margin-bottom: 1rem;
	padding-bottom: 0.5rem;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-flex-shrink: 1;
	-ms-flex-negative: 1;
	flex-shrink: 1;
	border-bottom: 1px solid #bbbdc0;
}

.event-date {
	display: inline-block;
	margin-right: 0.75rem;
	margin-bottom: 0.5rem;
	font-family: 'Work Sans', sans-serif;
	font-weight: 500;
	text-transform: uppercase;
}

.event-time {
	display: inline-block;
	margin-bottom: 0px;
	padding-left: 1.5rem;
	background-image: url('/20170713122722/assets/images/icon-time.svg');
	background-position: 0px 50%;
	background-size: 20px;
	background-repeat: no-repeat;
	font-family: 'Work Sans', sans-serif;
	font-weight: 500;
}

.news-events-header {
	margin-top: 1.5rem;
	margin-bottom: 0.65rem;
	font-family: Merriweather, serif;
	color: #075488;
	font-size: 2.5rem;
	line-height: 3rem;
	font-weight: 400;
}

.news-events-header-wrap {
	margin-bottom: 1rem;
}

.footer-nav-list {
	display: block;
	margin-top: 0.25rem;
	font-family: 'Work Sans', sans-serif;
	color: #fff;
	font-weight: 300;
	text-decoration: none;
}

.footer-nav-list:hover {
	color: #b3d236;
}

.container-3 {
	display: block;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
}

.container-3.signoff-container {
	max-width: none;
	padding-bottom: 1rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: center;
}

.footer-heading {
	margin-top: 0px;
	margin-bottom: 0.5rem;
	font-family: 'Work Sans', sans-serif;
	color: #fff;
	font-size: 1.15rem;
	line-height: 1.7rem;
	font-weight: 400;
	letter-spacing: -0.02rem;
	text-transform: uppercase;
}

.footer-col-wrap {
	width: 33%;
	min-width: 300px;
	padding-right: 1rem;
}

.footer-col-wrap.footer-col-2 {
	width: 100%;
	min-width: 0px;
}

.footer-col-wrap.footer-col-4 {
	width: 33%;
	min-width: 200px;
}

.footer-nav-item {
	margin-top: 0rem;
	margin-bottom: 0.25rem;
	font-weight: 300;
}

.div-block-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.footer-col-wrap .footer-nav-list:last-of-type {
	padding-left: 60px;
}

.submit-button {
	display: none;
}

.goog-te-combo {
	min-height: 34px;
	min-width: 220px;
	margin-top: 2px;
	padding: 8px 20px 8px 8px;
	font-size: 0.9rem;
	line-height: 1.5rem;
}

.google-translate {
	clear: both;
}

.social-media-wrapper {
	position: relative;
	z-index: 1000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 2.5rem;
	margin-top: 0rem;
	margin-bottom: 2rem;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.footer-right-col-wrap {
	min-width: 170px;
	padding-top: 0rem;
	padding-bottom: 0rem;
}

.mailing-list-footer {
	background-color: #467ba9;
	padding: 10px 15px !important;
	transition: all 300ms cubic-bezier(0.842, 0.007, 0.772, 1.001);
	text-align: center;
	font-family: 'Work Sans', sans-serif;
	color: #fff;
	font-weight: 500;
	font-size: 1rem;
	line-height: 1rem;
}


.mailing-list-footer:hover {
	color: #05436d;
	background-color: #b3d236;
}

.social-media-link-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 30px;
	height: 30px;
	margin-right: 0.4rem;
	margin-left: 0.4rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-radius: 0%;
	background-color: #467ba9;
	-webkit-transition: all 300ms cubic-bezier(0.842, 0.007, 0.772, 1.001);
	transition: all 300ms cubic-bezier(0.842, 0.007, 0.772, 1.001);
	text-align: center;
}

.social-media-link-block:hover {
	border-radius: 1px;
	background-color: #b3d236;
}

.social-media-link-block.first-social-link {
	margin-left: 0rem;
}

.social-media-link-block.first-social-link:hover {
	opacity: 1;
}

.footer-nav-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-top: 0rem;
	padding-bottom: 0rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.form-block {
	display: block;
}

.google-translate-outer-wrap {
	display: none;
}

.grip-logo-text-box {
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #fff;
	text-decoration: none;
	text-transform: uppercase;
}

.back-to-top {
	position: fixed;
	right: 10px;
	bottom: 5px;
	z-index: 10000;
	display: inline;
	width: 60px;
	height: 60px;
	margin-right: auto;
	margin-left: auto;
	padding: 0.5rem 1rem;
	border-radius: 50%;
	background-color: rgba(7, 84, 136, 0.51);
	background-image: url('/20170713122722/assets/images/arrow-white-up.svg');
	background-position: 50% 50%;
	background-size: 24px 24px;
	background-repeat: no-repeat;
	box-shadow: -1px 4px 8px 0 rgba(0, 0, 0, 0.35);
	opacity: 0;
	font-family: 'Work Sans', sans-serif;
	color: #fff;
	font-size: 0.8rem;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0.03rem;
	text-decoration: none;
	text-transform: uppercase;
	transition: opacity 300ms ease;
}

.back-to-top.show {
	opacity: 1;
}

.back-to-top:hover {
	background-color: #075488;
	box-shadow: -2px 9px 15px 2px rgba(0, 0, 0, 0.2);
	color: #ffffff;
	font-size: 0.8rem;
	letter-spacing: 0.03rem;
}

.back-to-top:active {
	box-shadow: -1px 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.signoff-links-wrap {
	margin-bottom: 0.5rem;
	float: left;
	font-size: 0.8rem;
	line-height: 1.2rem;
}

.footer-grip-link {
	width: 130px;
	height: 28px;
	min-width: 0px;
	margin-top: 1rem;
	padding-left: 32px;
	float: right;
	background-image: url('/20170713122722/assets/images/globalreach_knockout_fullwhite-optimized.svg');
	background-position: 50% 50%;
	background-size: 130px 28px;
	background-repeat: no-repeat;
	text-decoration: none;
}

.copyright-paragraph {
	display: inline-block;
	margin-top: 0rem;
	margin-right: 0.25rem;
	margin-bottom: 0.25rem;
	float: left;
	font-family: 'Work Sans', sans-serif;
	font-size: 0.9rem;
	line-height: 1.2rem;
	font-weight: 300;
}

.signoff-left {
	width: 70%;
	margin-top: 1rem;
	float: left;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	color: #fff;
	text-align: left;
}

.signoff-link {
	margin-right: 0.75rem;
	padding-bottom: 2px;
	box-shadow: inset 0 -1px 0 0 hsla(0, 0%, 100%, 0.4);
	font-family: 'Work Sans', sans-serif;
	color: #fff;
	font-size: 0.9rem;
	font-weight: 300;
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

.signoff-link:hover {
	box-shadow: inset 0 -1px 0 0 transparent;
	color: #b3d236;
	text-decoration: none;
}

.signoff-section {
	display: block;
	margin-right: auto;
	margin-left: auto;
	padding-right: 8vw;
	padding-left: 8vw;
	border-top: 1px solid hsla(0, 0%, 100%, 0.2);
	background-color: #032c47;
}

.mhn-link-wrap {
	margin-top: 0.5rem;
}

.mmc-link-wrap {
	margin-top: 2rem;
}

.footer-paragraph {
	padding-left: 1.6rem;
	background-image: url('/20170713122722/assets/images/hospital-white.svg');
	background-size: 18px 20px;
	background-repeat: no-repeat;
	font-family: 'Work Sans', sans-serif;
	color: #fff;
	font-weight: 300;
}

.footer-paragraph.phone-paragraph {
	padding-left: 1.6rem;
	background-image: url('/20170713122722/assets/images/cellphone-1-white.svg');
	background-position: 0px 50%;
	background-size: 20px 20px;
	background-repeat: no-repeat;
}


.multidiv-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 1rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
}

.footer-nav-list-link {
	display: block;
	margin-top: 0.25rem;
	padding-left: 14px;
	background-image: url('/20170713122722/assets/images/chevron-right_white.svg');
	background-position: 0px 50%;
	background-size: 8px 8px;
	background-repeat: no-repeat;
	font-family: 'Work Sans', sans-serif;
	color: #fff;
	font-weight: 300;
	text-decoration: none;
}

.footer-nav-list-link:hover {
	-webkit-transform: translate(5px, 0px);
	-ms-transform: translate(5px, 0px);
	transform: translate(5px, 0px);
	color: #b3d236;
}

.multicol-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 33%;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.callout-paragraph {
	font-size: 0.9rem;
	line-height: 1.45rem;
}

.two-part-mobile-nav-wrapper {
	display: none;
}

.mobile-menu-arrow {
	display: none;
}

.top {
	display: none;
	width: 100%;
	height: 0px;
}

.page-wrapper {
	position: relative;
	z-index: 1;
	background-image: none;
	background-size: auto;
	background-attachment: scroll;
}

.search-button {
	width: 2rem;
	height: 2rem;
	padding: 0px 1.3rem;
	background-image: url('/20170713122722/assets/images/search_vdmc-blue.svg');
	background-position: 50% 50%;
	background-size: 20px 20px;
	background-repeat: no-repeat;
}

.search-button:hover {
	background-image: url('/20170713122722/assets/images/search_graya1a4a8.svg');
	background-size: 20px 20px;
}

.mobile-search-button {
	display: none;
	width: 2rem;
	height: 2rem;
	padding: 0px 1.3rem;
	background-image: url('/20170713122722/assets/images/search_vdmc-blue.svg');
	background-position: 50% 50%;
	background-size: 20px 20px;
	background-repeat: no-repeat;
}

.mobile-search-button:hover {
	background-image: url('/20170713122722/assets/images/search_graya1a4a8.svg');
	background-size: 20px 20px;
}

.mobile-search-wrap {
	position: absolute;
	top: 100%;
	right: 2px;
	display: none;
	overflow: hidden;
	width: 400px;
	height: 0px;
	min-height: 0px;
	margin-top: -1px;
	margin-right: auto;
	margin-left: auto;
	background-color: rgba(0, 0, 0, 0.8);
	opacity: 1;
}

.mobile-search-form-block {
	display: block;
	min-width: 400px;
	margin-bottom: 0px;
	padding: 1rem;
}

.mobile-search-form {
	text-align: left;
}

.mobile-search-field {
	display: inline-block;
	width: 70%;
	height: 2.5rem;
	margin-right: 0%;
	margin-bottom: 0px;
	float: left;
	border-radius: 5px 0px 0px 5px;
	font-size: 0.95rem;
	margin-bottom: 0px !important;
}

.mobile-search-field:focus {
	border-color: transparent;
	box-shadow: inset 0 0 0 0 #000;
}

.button-3 {
	display: inline-block;
	margin-top: 0.5rem;
	margin-right: 20px;
	margin-bottom: 0.5rem;
	padding: 0.375rem 1rem;
	border-radius: 5px;
	background-color: #000;
	-webkit-transition: background-color 250ms ease, text-shadow 200ms ease, font-size 200ms ease, -webkit-transform 250ms ease;
	transition: background-color 250ms ease, text-shadow 200ms ease, font-size 200ms ease, -webkit-transform 250ms ease;
	transition: transform 250ms ease, background-color 250ms ease, text-shadow 200ms ease, font-size 200ms ease;
	transition: transform 250ms ease, background-color 250ms ease, text-shadow 200ms ease, font-size 200ms ease, -webkit-transform 250ms ease;
	color: #fff;
	font-size: 0.85rem;
	line-height: 1.45rem;
	font-weight: 700;
	text-align: center;
	letter-spacing: 0.04rem;
	text-decoration: none;
	text-transform: uppercase;
}

.button-3:hover {
	background-color: #595b5f;
	-webkit-transform: translate(0px, 2px);
	-ms-transform: translate(0px, 2px);
	transform: translate(0px, 2px);
	color: #fff;
	text-shadow: 1px 1px 6px #fff;
}

.header-search-session-message {
	width: 100%;
	height: 2px;
	max-height: 2px;
	max-width: 100%;
	margin-top: 0px;
	padding: 0px;
	background-color: transparent;
}

.mobile-logo-link {
	display: none;
	width: 280px;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition-property: none;
	transition-property: none;
	color: #fff;
	font-size: 1.625rem;
	line-height: 1.25rem;
	font-weight: 600;
	text-align: center;
	cursor: pointer;
}

.mobile-logo-link:hover {
	color: #fff;
}

.copyright-wrap {
	float: left;
}

.inside-page-wrapper {
	padding-top: 128px;
	background-image: -webkit-linear-gradient(270deg, #fff, #fff), -webkit-linear-gradient(270deg, rgba(0, 152, 255, 0.05), rgba(0, 152, 255, 0.05)), url('/20170713122722/assets/images/p6_2X_1p6_@2X.png');
	background-image: linear-gradient(180deg, #fff, #fff), linear-gradient(180deg, rgba(0, 152, 255, 0.05), rgba(0, 152, 255, 0.05)), url('/20170713122722/assets/images/p6_2X_1p6_@2X.png');
	background-position: 0px 0px, 0px 0px, 0px 0px;
	background-size: auto, auto, 8px;
}

.left-nav-section-title {
	margin-top: 0rem;
	margin-bottom: 0rem;
	border-bottom: 1px none #d6d2c6;
	font-family: Merriweather, serif;
	font-size: 1.15rem;
	line-height: 1.55rem;
	font-weight: 700;
	letter-spacing: -0.02rem;
	text-decoration: none;
}

.left-nav-list-item {
	margin-bottom: 0rem;
}

.left-nav-list-item.greatgrandchild-li {
	margin-left: 0.5rem;
}

.left-nav-list-item.grandchild-li {
	margin-left: 1rem;
}

.left-nav-list-item.grandchild-li.grandchild-open {
	background-color: rgba(0, 0, 0, 0.05);
	cursor: pointer;
}


.left-nav-wrapper {
	position: relative;
	width: 22%;
	height: 100%;
	max-width: none;
	min-width: 180px;
	margin-right: 3.25vw;
	padding-top: 1.45rem;
}

.intro-paragraph-2 {
	display: block;
	color: #636363;
	font-size: 1.3rem;
	line-height: 2rem;
	font-weight: 500;
}

.breadcrumbs-link-2 {
	border-bottom: 1px dotted #85c1f1;
	color: #636363;
	text-decoration: none;
}

.breadcrumbs-link-2:hover {
	border-bottom-color: transparent;
	color: #000;
}

.content-outer-wrap {
	position: relative;
	display: block;
	max-width: 1400px;
	margin-right: auto;
	margin-left: auto;
	padding: 1.5rem 1.75rem 2.5rem;
	background-color: #fff;
	box-shadow: none;
	min-height: 800px;
}

.page-title-wrap {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}

.inside-content-section {
	position: relative;
	display: block;
	margin-right: auto;
	margin-left: auto;
	padding-top: 500px;
	padding-right: 1.5rem;
	padding-left: 1.5rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.left-nav-list-link {
	display: block;
	padding: 0.5rem 0.9rem 0.5rem 0.35rem;
	color: #075488;
	text-decoration: none;
}

.left-nav-list-link:hover {
	-webkit-transform: translate(8px, 0px);
	-ms-transform: translate(8px, 0px);
	transform: translate(8px, 0px);
	color: #313233;
}

.left-nav-list-link.w--current {
	background-color: #cfdde7;
	color: #075488;
	display: block;
}

.left-nav-list-link.w--current:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.left-nav-list-link.grandchild-link {
	font-size: 0.9rem;
	line-height: 1.3rem;
	font-weight: 400;
}

.left-nav-list-link.greatgrandchild-link {
	padding-left: 0.85rem;
	font-size: 0.82rem;
	line-height: 1.22rem;
	font-weight: 400;
}

.left-nav-list-link.greatgrandchild-link.w--current {
	padding-left: 0.85rem;
	background-color: transparent;
	background-image: url('/20170713122722/assets/images/chevron-right_vdmc-blue.svg');
	background-position: 0px 50%;
	background-size: 8px 8px;
	background-repeat: no-repeat;
	font-size: 0.8rem;
	line-height: 1.2rem;
}

.left-nav-list-link.greatgrandchild-link.w--current:hover {
	color: #075488;
}

.left-nav-link-wrap {
	border-bottom: 1px none #e0ded6;
}

.main-content-outer-wrap {
	display: block;
	width: 100%;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.left-nav-list {
	margin-top: 0rem;
	margin-bottom: 0rem;
	background-color: transparent;
	font-family: 'Work Sans', sans-serif;
	font-size: 1rem;
	line-height: 1.375rem;
	font-weight: 400;
	text-decoration: none;
}

.inside-page-banner-wrap {
	position: fixed;
	width: 100%;
	height: 500px;
	background-color: #fff;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.inside-page-banner-wrap.inside-banner-1 {
	height: 500px;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.inside-page-banner-wrap.inside-banner-2 {
	height: 500px;
}

.main-content-inner-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.left-nav-section-link {
	width: 100%;
	margin-bottom: 7px;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	text-decoration: none;
}

.left-nav-section-link:hover {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.left-nav-section-title-wrap {
	margin-bottom: 6px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.accent-gradient-3 {
	width: 100%;
	height: 5px;
	background-image: -webkit-linear-gradient(0deg, #b3d236, rgba(179, 210, 54, 0.1));
	background-image: linear-gradient(90deg, #b3d236, rgba(179, 210, 54, 0.1));
}

.div-block-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.accent-gradient-2b {
	width: 100%;
	height: 5px;
	background-image: -webkit-linear-gradient(0deg, #b3d236, rgba(179, 210, 54, 0.1));
	background-image: linear-gradient(90deg, #b3d236, rgba(179, 210, 54, 0.1));
}

.current-line {
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 5px;
	width: 100%;
	height: 1px;
	border-bottom: 1px solid #b3d236;
}

.current-line.inactive-fake {
	opacity: 0;
}

.nav-accent-active-fake {
	position: absolute;
	top: 38px;
	bottom: 0px;
	z-index: 10000;
	display: none;
	width: 12px;
	height: 12px;
	background-color: #b3d236;
	opacity: 1;
}

.fixed-wrap {
	position: fixed;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	background-image: url('/20170713122722/assets/images/Projects_Healthcare__VanDiest_1-tiny.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

html.w-mod-js *[data-ix="logo-image-on-load"] {
	width: 100%;
}

html.w-mod-js *[data-ix="callout-reveal"] {
	opacity: 0;
	-webkit-transform: translate(0px, 300px) scale(0.9500000000000006, 0.9500000000000006);
	-ms-transform: translate(0px, 300px) scale(0.9500000000000006, 0.9500000000000006);
	transform: translate(0px, 300px) scale(0.9500000000000006, 0.9500000000000006);
}

html.w-mod-js *[data-ix="testimonial-reveal"] {
	opacity: 0;
	-webkit-transform: translate(0px, 300px) scale(0.9500000000000006, 0.9500000000000006);
	-ms-transform: translate(0px, 300px) scale(0.9500000000000006, 0.9500000000000006);
	transform: translate(0px, 300px) scale(0.9500000000000006, 0.9500000000000006);
}

html.w-mod-js *[data-ix="fixed-image-lazy-load"] {
	opacity: 0;
}

@media (max-width: 991px) {
	h1 {
		font-size: 2.35rem;
		line-height: 3.25rem;
	}

	.header-section {
		height: 94.1875px;
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.button.mobile-search-go-button {
		margin-top: 0rem;
		margin-right: 0rem;
		margin-bottom: 0rem;
		box-shadow: none;
		cursor: pointer;
	}

	.right-side-col {
		width: 100%;
		max-width: none;
		min-width: 0px;
		margin-top: 2rem;
		margin-left: 0rem;
		padding: 1rem 0rem 0rem;
		border-top: 3px dotted #adadad;
		background-color: transparent;
	}

	.breadcrumbs-container {
		padding-left: 0rem;
	}

	.main-content {
		width: 100%;
		margin-top: 2rem;
	}

	.style-tile-container {
		max-width: 725px;
	}

	.slide-nav-main {
		max-width: 500px;
	}

	.ken-burns-wrapper {
		height: 450px;
	}

	.arrow-icon {
		font-size: 1.25rem;
	}

	.hero-rotator-widget-kburns {
		width: auto;
		height: auto;
		max-height: none;
		min-height: 0px;
		background-color: #05436d;
	}

	.hero-rotator-section-kburns {
		position: relative;
		width: 100%;
		padding-top: 92px;
	}

	.two-part-button-list {
		position: absolute;
		left: 0px;
		top: 0px;
		z-index: 150;
		display: block;
		width: 100%;
		margin-left: 100%;
		padding-bottom: 100vh;
		border-top: 1px none #21272e;
		background-color: #494848;
	}

	.secondary-nav-list {
		display: none;
		height: 100%;
		margin-top: 0rem;
		margin-right: 0px;
		margin-bottom: 0em;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
	}

	.ecomm-header-wrap {
		margin-left: 30px;
	}

	.header-content-right-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-top: 0.5rem;
		padding-bottom: 0.75rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.header-utility-wrap {
		display: none;
		padding-right: 1.5rem;
	}

	.form-field.header-search-field {
		font-size: 1rem;
	}

	.secondary-nav-list-item {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 100%;
		padding: 0rem 0px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		align-self: stretch;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
	}

	.nav-section.w-nav {
		position: absolute;
		top: 24px;
	}

	.secondary-nav-list-link {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: 0rem;
		margin-left: 0rem;
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		font-size: 0.6875rem;
		font-weight: 500;
	}

	.ecomm-header-link {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-left: 0rem;
		padding-right: 0rem;
		padding-left: 14px;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		font-size: 0.6875rem;
		font-weight: 500;
	}

	.ecomm-header-link.account-icon {
		width: 2.5rem;
		height: 2.5rem;
		margin-right: 1.25rem;
	}

	.ecomm-header-link.cart-icon {
		height: 2.5rem;
		margin-right: 0.5rem;
	}

	.header-search-wrap {
		display: none;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.menu-button {
		padding: 0px 1rem;
		float: none;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: row-reverse;
		-ms-flex-direction: row-reverse;
		flex-direction: row-reverse;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
		background-color: transparent;
		-webkit-transition: all 250ms ease;
		transition: all 250ms ease;
		color: #075488;
	}

	.menu-button:hover {
		color: #494948;
	}

	.menu-button.w--open {
		background-color: #fff;
		color: #075488;
		float: none;
	}

	.header-mobile-search {
		display: none;
		overflow: hidden;
		height: auto;
	}

	.nav-container {
		width: auto;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.nav-drop-wrap {
		width: 100%;
	}

	.header-menu-wrap {
		display: block;
		float: left;
	}

	.menu-button-text {
		display: block;
		margin-left: 0.25rem;
		float: right;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		font-family: 'Work Sans', sans-serif;
		font-size: 1rem;
		line-height: 3.5rem;
		font-weight: 500;
		letter-spacing: -0.02em;
		text-transform: uppercase;
	}

	.logo-link {
		width: 250px;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		align-self: center;
		font-size: 1.375rem;
	}

	.nav-drop-toggle {
		display: block;
	}

	.nav-section {
		float: left;
	}

	.menu-button-icon {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 100%;
		padding-top: 1px;
		float: left;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		align-self: center;
		font-size: 2rem;
		line-height: 3.5rem;
	}

	.main-nav-link {
		display: block;
		padding-top: 1rem;
		padding-left: 1rem;
		border-bottom: 1px solid #bbbdc0;
		text-align: left;
	}

	.main-nav-link.w--current {
		padding-left: 2rem;
		background-image: url('/20170713122722/assets/images/vdmc-square-green.svg');
		background-position: 0px 50%;
		background-size: 18px 18px;
		background-repeat: no-repeat;
		color: #075488;
	}

	.main-nav-link.two-part-mobile-link {
		width: 100%;
		margin-right: 0px;
		margin-left: 0px;
	}

	.nav-menu-wrap {
		z-index: 1001;
		display: block;
		width: 80vw;
		margin-top: 74px;
		background-color: #fff;
		box-shadow: 7px 7px 10px 0 rgba(0, 0, 0, 0.3);
	}

	.header-content-left-wrap {
		display: none;
	}

	.slide-color-overlay {
		height: 450px;
	}

	.arrow-icon-right {
		font-size: 1.25rem;
	}

	.slide-caption-container {
		position: static;
		display: block;
		width: 100%;
		padding: 1.5rem 3.5rem 5rem;
	}

	.slide-header {
		max-width: 800px;
		font-size: 3.5rem;
		line-height: 4.5rem;
	}

	.slide-caption-wrapper {
		position: relative;
		top: 0vh;
		width: 100%;
		max-width: 100%;
	}

	.home-section.row-2 {
		padding-right: 3.75rem;
		padding-left: 3.75rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.home-section.row-3 {
		position: relative;
		padding-bottom: 6rem;
	}

	.home-section.row-4 {
		padding-top: 1rem;
		padding-right: 3.75rem;
		padding-left: 3.75rem;
	}

	.container.footer-container {
		padding-right: 3.75rem;
		padding-left: 3.75rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.quicklink-link {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.quicklink-link:hover {
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.quicklink-link.ql-center {
		border-right: 1px solid #bbbdc0;
		border-left: 1px solid #bbbdc0;
	}

	.quicklink-header {
		padding-right: 0px;
		background-image: none;
		font-size: 1.1rem;
		line-height: 1.4rem;
		text-align: center;
	}

	.slide-paragraph {
		max-width: 600px;
		min-width: 0px;
	}

	.quicklink-icon-wrap {
		width: 2.5rem;
		height: 2.5rem;
	}

	.quicklink-icon-wrap.providericon {
		background-size: 28px 28px;
	}

	.quicklink-icon-wrap.billpayicon {
		background-size: 28px 28px;
	}

	.quicklink-icon-wrap.portalicon {
		background-size: 30px 30px;
	}

	.callouts-cb {
		top: 2rem;
		width: 100%;
		padding-right: 1rem;
	}

	.callout-item-wrap {
		margin-left: 1rem;
	}

	.welcome-cb {
		position: relative;
		top: 2rem;
		width: 100%;
		margin-bottom: 1rem;
		margin-left: 0vw;
		padding-top: 0rem;
		padding-right: 0vw;
	}

	.testimonials-cb {
		padding-right: 3.75rem;
		padding-left: 3.75rem;
	}

	.news-events-cb {
		width: 47%;
	}

	.slide-caption-inner-wrap {
		padding-right: 2.5rem;
		padding-bottom: 1.5rem;
	}

	.slide-accent-block {
		right: -25px;
		bottom: -25px;
		width: 50px;
		height: 50px;
	}

	.testimonial-paragraph {
		font-size: 1.25rem;
		line-height: 2.15rem;
	}

	.testimonial-text-wrapper {
		padding-top: 1.5rem;
		padding-right: 3rem;
		padding-left: 3rem;
	}

	.quote-wrapper {
		width: 50px;
		height: 50px;
	}

	.news-content-wrap {
		width: 100%;
	}

	.news-event-item {
		width: 100%;
		-webkit-box-flex: 0;
		-webkit-flex-grow: 0;
		-ms-flex-positive: 0;
		flex-grow: 0;
		-webkit-flex-basis: auto;
		-ms-flex-preferred-size: auto;
		flex-basis: auto;
	}

	.container-3 {
		max-width: 991px;
	}

	.container-3.signoff-container {
		padding-top: 0.5rem;
		padding-bottom: 3rem;
		text-align: left;
	}

	.footer-heading {
		margin-top: 0rem;
	}

	.footer-col-wrap {
		width: 100%;
		min-width: 0px;
		margin-top: 0.5rem;
	}

	.footer-col-wrap.footer-col-2 {
		width: 100%;
		min-width: 0px;
	}

	.footer-col-wrap.footer-col-4 {
		width: 50%;
		padding-right: 0rem;
	}

	.footer-nav-item {
		margin-bottom: 0.35rem;
	}

	.social-outer-wrap {
		width: 100%;
	}

	.footer-right-col-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 50%;
		margin-top: 1.5rem;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.social-media-link-block {
		-webkit-transition-property: all;
		transition-property: all;
		-webkit-transition-duration: 300ms;
		transition-duration: 300ms;
	}

	.social-media-link-block:hover {
		border-radius: 0%;
	}

	.social-media-link-block.first-social-link {
		margin-left: 0rem;
	}

	.footer-nav-wrapper {
		width: 100%;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.google-translate-outer-wrap {
		width: 50%;
	}

	.grip-logo-text-box {
		float: right;
	}

	.back-to-top {
		right: 5px;
		bottom: 15px;
		display: inline-block;
		width: 50px;
		height: 50px;
	}

	.signoff-links-wrap {
		margin-left: 0rem;
	}

	.footer-grip-link {
		margin-top: 1rem;
	}

	.copyright-paragraph {
		margin-bottom: 0.5rem;
	}

	.signoff-left {
		width: 75%;
	}

	.multidiv-wrap {
		width: 50%;
		min-width: 0px;
		padding-right: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.multicol-wrap {
		width: 100%;
		margin-top: 1rem;
		border-top: 1px solid hsla(0, 0%, 100%, 0.4);
	}

	.two-part-mobile-nav-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);
	}

	.mobile-menu-arrow {
		display: block;
		width: 60px;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		border-bottom: 1px solid #bbbdc0;
		border-left: 1px solid #bbbdc0;
		background-image: url('/20170713122722/assets/images/chevron-right_vdmc-blue.svg');
		background-position: 50% 50%;
		background-size: 20px 20px;
		background-repeat: no-repeat;
	}

	.mobile-search-button {
		display: block;
		width: 57px;
		height: 57px;
		float: right;
	}

	.mobile-search-wrap {
		position: absolute;
		top: 100%;
		right: 0px;
		display: block;
		width: 100%;
		margin-top: 0px;
		margin-bottom: 0rem;
	}

	.mobile-search-form-block {
		padding: 0rem;
	}

	.mobile-search-form {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding: 1rem;
		background-color: #83a9c3;
	}

	.mobile-search-field {
		width: 100%;
		border-radius: 0px;
		background-image: -webkit-linear-gradient(270deg, #fff, #fff);
		background-image: linear-gradient(180deg, #fff, #fff);
	}

	.mobile-logo-link {
		left: 1.75rem;
		display: block;
		width: 300px;
		margin-right: auto;
		margin-left: auto;
		float: none;
		font-size: 1.375rem;
	}

	.inside-page-wrapper {
		padding-top: 90px;
	}

	.left-nav-wrapper {
		display: none;
	}

	.intro-paragraph-2 {
		font-size: 1.2rem;
		line-height: 1.9rem;
	}

	.content-outer-wrap {
		height: 100%;
		padding-top: 0rem;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.inside-content-section {
		padding-top: 400px;
	}

	.main-content-outer-wrap {
		display: block;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
	}

	.inside-page-banner-wrap {
		height: 250px;
	}

	.inside-page-banner-wrap.inside-banner-1 {
		height: 400px;
	}

	.inside-page-banner-wrap.inside-banner-2 {
		height: 400px;
	}

	.main-content-inner-wrap {
		display: block;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}
}

@media (max-width: 767px) {
	h1 {
		font-size: 2.2rem;
		line-height: 3.1rem;
	}

	h2 {
		font-size: 1.7rem;
		line-height: 2.6rem;
	}

	ul {
		padding-left: 20px;
	}

	ol {
		padding-left: 20px;
	}

	.header-section {
		height: 91px;
		padding-left: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.nav-section.w-nav {
		top: 19px;
	}

	.button.mobile-search-go-button {
		height: auto;
		margin-top: 0rem;
		margin-right: 0rem;
		margin-bottom: 0rem;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		box-shadow: none;
	}

	.intro-paragraph {
		font-size: 1.25rem;
		line-height: 1.9rem;
	}

	.breadcrumbs-list-item {
		margin-bottom: 0.5rem;
	}

	.breadcrumbs-list {
		display: inline-block;
	}

	.right-side-col {
		padding-top: 0.5rem;
	}

	.breadcrumbs-container {
		font-size: 0.85rem;
	}

	.style-tile-container {
		max-width: 550px;
	}

	.left-arrow-two {
		display: none;
	}

	.slide-nav-main {
		display: none;
		max-width: 300px;
		background-image: none;
	}

	.ken-burns-wrapper {
		height: 400px;
	}

	.ken-burns-wrapper._1 {
		background-size: cover;
		background-repeat: no-repeat;
	}

	.right-arrow-two {
		display: none;
	}

	.arrow-icon {
		top: 100px;
	}

	.hero-rotator-section-kburns {
		padding-top: 90px;
	}

	.secondary-nav-list {
		margin-right: 0px;
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		align-self: stretch;
		-webkit-box-flex: 1;
		-webkit-flex: auto;
		-ms-flex: auto;
		flex: auto;
	}

	.header-search-form {
		padding: 1rem;
	}

	.header-content-right-wrap {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.header-utility-wrap {
		display: none;
		margin-bottom: 0rem;
		padding-top: 0.5rem;
		padding-right: 0rem;
	}

	.secondary-nav-list-item {
		max-width: 100%;
		-webkit-box-flex: 1;
		-webkit-flex: auto;
		-ms-flex: auto;
		flex: auto;
	}

	.secondary-nav-list-link {
		padding-right: 0.5em;
		padding-left: 0.5em;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-content: center;
		-ms-flex-line-pack: center;
		align-content: center;
		-webkit-box-flex: 1;
		-webkit-flex: auto;
		-ms-flex: auto;
		flex: auto;
	}

	.ecomm-header-link {
		padding-right: 0.5em;
		padding-left: 0.5em;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-content: center;
		-ms-flex-line-pack: center;
		align-content: center;
		-webkit-box-flex: 1;
		-webkit-flex: auto;
		-ms-flex: auto;
		flex: auto;
	}

	.header-search-wrap {
		display: none;
	}

	.menu-button {
		padding-right: 1rem;
		padding-left: 1rem;
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		align-self: stretch;
	}

	.nav-container {
		margin-right: 0px;
		margin-left: 0px;
		padding-right: 0rem;
	}

	.menu-button-text {
		display: none;
	}

	.logo-link {
		top: 0px;
		width: 230px;
		margin-right: 0rem;
	}

	.nav-menu-wrap {
		margin-top: 72px;
	}

	.header-content-left-wrap {
		display: none;
		padding-top: 1rem;
		padding-bottom: 0rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.slide-color-overlay {
		height: 400px;
	}

	.arrow-icon-right {
		top: 100px;
	}

	.slide-caption-container {
		padding: 1rem 3rem 3.5rem 2.5rem;
	}

	.slide-header {
		font-size: 3rem;
		line-height: 4rem;
	}

	.home-section.row-2 {
		padding-right: 3rem;
		padding-left: 3rem;
	}

	.home-section.row-3 {
		padding-top: 5rem;
	}

	.home-section.row-4 {
		padding-right: 3rem;
		padding-left: 3rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.container.footer-container {
		padding-right: 3rem;
		padding-left: 3rem;
	}

	.quicklink-link {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.quicklink-header {
		font-size: 1rem;
		line-height: 1.1rem;
		text-align: center;
	}

	.testimonials-cb {
		padding-right: 3rem;
		padding-left: 3rem;
	}

	.news-events-cb {
		width: 100%;
	}

	.slide-caption-inner-wrap {
		padding-right: 2rem;
	}

	.slide-accent-block {
		right: -20px;
		bottom: -20px;
		width: 40px;
		height: 40px;
	}

	.slider {
		height: 400px;
	}

	.testimonial-author {
		font-size: 0.95rem;
		line-height: 1.5rem;
	}

	.testimonial-paragraph {
		font-size: 1.15rem;
		line-height: 2.05rem;
	}

	.rooster.tesimonial-rooter {
		margin-bottom: 1.25rem;
	}

	.testimonial-slide-nav-dots {
		display: block;
	}

	.testimonial-arrow-left:hover {
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.testimonial-text-wrapper {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.testimonial-arrow-right:hover {
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.news-event-item {
		min-width: 0px;
	}

	.news-events-header {
		margin-top: 0rem;
	}

	.footer-nav-list {
		margin-top: 0.55rem;
		margin-left: 0rem;
	}

	.container-3 {
		max-width: 767px;
	}

	.container-3.signoff-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 1rem;
		padding-bottom: 1.5rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		text-align: left;
	}

	.footer-col-wrap {
		width: 100%;
		min-width: 200px;
		padding-right: 1rem;
	}

	.footer-col-wrap.footer-col-2 {
		width: 50%;
	}

	.footer-col-wrap.footer-col-4 {
		width: 100%;
		min-width: 175px;
	}

	.footer-nav-item {
		margin-top: 0rem;
	}

	.social-outer-wrap {
		width: 100%;
		margin-top: 1.4rem;
		text-align: center;
	}

	.social-media-wrapper {
		float: none;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.footer-right-col-wrap {
		width: 100%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		border-top: 1px solid hsla(0, 0%, 100%, 0.4);
	}

	.social-media-link-block {
		margin-right: 0.75rem;
	}

	.footer-nav-wrapper {
		width: 100%;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.google-translate-outer-wrap {
		width: 100%;
	}

	.back-to-top {
		width: 50px;
		height: 50px;
	}

	.signoff-links-wrap {
		float: none;
		text-align: center;
		line-height: 1rem;
	}

	.footer-grip-link {
		display: block;
		margin: 1.5rem 0px 0rem;
		float: none;
	}

	.copyright-paragraph {
		margin-right: 0.5rem;
		margin-bottom: 0.75rem;
		float: none;
		text-align: left;
	}

	.signoff-left {
		width: 100%;
		margin-top: 0.5rem;
		text-align: center;
	}

	.signoff-link {
		margin-right: 0.75rem;
		margin-bottom: 0.5rem;
		display: inline-block;
	}

	.signoff-section {
		padding-right: 3rem;
		padding-left: 3rem;
	}

	.mhn-link-wrap {
		margin-top: 0rem;
	}

	.mmc-link-wrap {
		margin-top: 0rem;
	}

	.multidiv-wrap {
		width: 100%;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.footer-nav-list-link {
		margin-top: 0.25rem;
		margin-left: 0rem;
	}

	.footer-nav-list-link:hover {
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.multicol-wrap {
		margin-top: 0rem;
		padding-top: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		border-top-style: none;
	}

	.mobile-menu-arrow {
		background-size: 16px 16px;
	}

	.mobile-menu-arrow:hover {
		background-size: 16px 16px;
	}

	.top {
		display: none;
		height: 0px;
	}

	.mobile-search-button {
		display: block;
		width: 57px;
		height: 57px;
	}

	.mobile-search-wrap {
		position: absolute;
		background-color: #83a9c3;
	}

	.mobile-search-form-block {
		padding: 0rem;
	}

	.mobile-search-form {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding: 1rem;
		background-color: #83a9c3;
	}

	.mobile-search-field {
		width: 100%;
		border-radius: 0px;
	}

	.button-3 {
		font-family: 'Work Sans', sans-serif;
		font-weight: 500;
	}

	.mobile-logo-link {
		right: 0.25rem;
		display: block;
		width: 300px;
	}

	.copyright-wrap {
		float: none;
		text-align: center;
	}

	.inside-page-wrapper {
		padding-top: 90px;
	}

	.intro-paragraph-2 {
		font-size: 1.1rem;
		line-height: 1.8rem;
	}

	.content-outer-wrap {
		padding-right: 1.5rem;
		padding-bottom: 2rem;
		padding-left: 1.5rem;
	}

	.inside-content-section {
		padding-top: 300px;
	}

	.inside-page-banner-wrap.inside-banner-1 {
		height: 300px;
	}

	.inside-page-banner-wrap.inside-banner-2 {
		height: 300px;
	}

	.block-quote {
		font-size: 1.1rem;
		line-height: 1.8rem;
	}
}

@media (max-width: 599px) {
	.container.quicklink-container {
		flex-wrap: wrap;
		justify-content: center;
	}

	.quicklink-link {
		width: 33.33%;
	}

	.quicklink-link.ql-center {
		border-right: 0;
		border-left: 0;
	}
}

@media (max-width: 479px) {
	h1 {
		font-size: 2rem;
		line-height: 2.9rem;
	}

	h2 {
		font-size: 1.5rem;
		line-height: 2.4rem;
	}

	h3 {
		font-size: 1.35rem;
		line-height: 2.15rem;
	}

	.header-section {
		height: 70px;
	}

	.nav-section.w-nav {
		top: 10px;
	}

	.button.mobile-search-go-button {
		min-width: 0px;
		font-size: 0.95rem;
	}

	.intro-paragraph {
		font-size: 1.15rem;
		line-height: 1.8rem;
	}

	.style-tile-container {
		max-width: none;
	}

	.slide-nav-main {
		display: none;
		height: 32px;
		max-width: 200px;
	}

	.ken-burns-wrapper {
		height: 225px;
	}

	.ken-burns-wrapper._1 {
		background-image: url('/20170713122722/assets/images/TS-612721732_gray_1903x1000.jpg');
		background-position: 50% 50%;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.hero-rotator-section-kburns {
		padding-top: 70px;
	}

	.secondary-nav-list {
		max-width: 100%;
		-webkit-flex-basis: 50%;
		-ms-flex-preferred-size: 50%;
		flex-basis: 50%;
	}

	.header-search-form {
		width: 100%;
	}

	.button-2.header-search-button {
		padding-right: 1em;
		padding-left: 1em;
	}

	.ecomm-header-wrap {
		margin-left: 1rem;
	}

	.header-content-right-wrap {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.header-utility-wrap {
		width: 100%;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.form-field.header-search-field {
		font-size: 0.875rem;
	}

	.secondary-nav-list-item {
		max-width: 100%;
	}

	.secondary-nav-list-link {
		padding-right: 0.5em;
		padding-left: 0.25em;
	}

	.ecomm-header-link {
		padding-right: 0.5em;
		padding-left: 0.25em;
	}

	.ecomm-header-link.account-icon {
		margin-right: 0.5rem;
	}

	.ecomm-header-link.cart-icon {
		padding-right: 0em;
	}

	.header-search-wrap {
		width: 100%;
		padding-right: 1rem;
		padding-left: 0vw;
	}

	.menu-button {
		padding-right: 1rem;
		padding-left: 1rem;
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		align-self: stretch;
	}

	.nav-container {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.menu-button-text {
		display: none;
	}

	.logo-link {
		width: 200px;
		margin-right: 0px;
		padding-left: 0px;
	}

	.nav-menu-wrap {
		width: 100vw;
		margin-top: 68px;
	}

	.slide-color-overlay {
		height: 225px;
	}

	.slide-caption-container {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.slide-header {
		font-size: 2.5rem;
		line-height: 3.25rem;
	}

	.home-section.row-2 {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.home-section.row-4 {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.container.quicklink-container {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.container.footer-container {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.quicklink-link {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		border-right: 1px none #bbbdc0;
		border-left: 1px none #bbbdc0;
	}

	.quicklink-link.ql-left-right {
		padding-left: 1rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		border-right-style: none;
		border-left-style: none;
	}

	.quicklink-header {
		line-height: 1.1rem;
		text-align: center;
	}

	.slide-paragraph {
		font-size: 1.1rem;
		line-height: 1.6rem;
	}

	.quicklink-icon-wrap {
		display: block;
		margin-right: 0rem;
		margin-bottom: 0.5rem;
	}

	.testimonials-cb {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.news-events-cb {
		width: 100%;
	}

	.slide-caption-inner-wrap {
		margin-top: 0rem;
	}

	.slide-accent-block {
		right: -15px;
		bottom: -15px;
	}

	.home-paragraph {
		font-size: 1.1rem;
		line-height: 1.8rem;
	}

	.slider {
		height: 500px;
	}

	.testimonial-author {
		line-height: 1.5rem;
	}

	.rooster.tesimonial-rooter {
		display: none;
	}

	.testimonial-slide-nav-dots {
		display: none;
	}

	.testimonial-arrow-left {
		display: block;
	}

	.testimonial-text-wrapper {
		padding-top: 1rem;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.quote-wrapper {
		width: 45px;
		height: 45px;
	}

	.testimonial-arrow-right {
		display: block;
	}

	.news-events-header {
		font-size: 2rem;
		line-height: 2.5rem;
	}

	.footer-nav-list {
		margin-left: 0rem;
	}

	.container-3 {
		max-width: 479px;
	}

	.container-3.signoff-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 0rem;
		padding: 0.5rem 1.5rem 1rem;
	}

	.footer-col-wrap {
		width: 100%;
		min-width: 0px;
		padding-right: 1rem;
	}

	.footer-col-wrap.footer-col-2 {
		width: 100%;
		padding-right: 0rem;
	}

	.footer-nav-item {
		margin-bottom: 0.5rem;
	}

	.footer-right-col-wrap {
		width: 100%;
	}

	.back-to-top {
		padding-bottom: 1rem;
		border-radius: 50%;
	}

	.signoff-links-wrap {
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}

	.footer-grip-link {
		display: block;
		margin-top: 1rem;
		margin-right: auto;
		margin-left: auto;
	}

	.copyright-paragraph {
		margin-right: 0.25rem;
		margin-bottom: 0rem;
		text-align: center;
	}

	.signoff-link {
		display: inline-block;
	}

	.signoff-section {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.mmc-link-wrap {
		margin-top: 0.5rem;
	}

	.multidiv-wrap {
		width: 100%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.footer-nav-list-link {
		margin-left: 0rem;
		line-height: 1.25rem;
	}

	.multicol-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.mobile-menu-arrow {
		width: 17%;
	}

	.mobile-search-wrap {
		width: 100%;
	}

	.mobile-search-form-block {
		min-width: 100%;
	}

	.mobile-search-field {
		float: left;
		background-image: -webkit-linear-gradient(270deg, #fff, #fff);
		background-image: linear-gradient(180deg, #fff, #fff);
	}

	.mobile-logo-link {
		right: 0.25rem;
		width: 210px;
		padding-left: 0px;
	}

	.inside-page-wrapper {
		padding-top: 70px;
	}

	.intro-paragraph-2 {
		font-size: 1.15rem;
		line-height: 1.8rem;
	}

	.content-outer-wrap {
		padding-right: 1rem;
		padding-bottom: 2rem;
		padding-left: 1rem;
	}

	.inside-content-section {
		padding-top: 175px;
		padding-right: 0.75rem;
		padding-left: 0.75rem;
	}

	.inside-page-banner-wrap.inside-banner-1 {
		height: 175px;
	}

	.inside-page-banner-wrap.inside-banner-2 {
		height: 175px;
	}
}

/****************** Webflow Styles *****************/
/*************************************************/
html {
	font-size: 1rem;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

/* ADJUST HEADER HEIGHT AND SLIDESHOW CAPTION ON LAPTOPS */
@media screen and (max-width: 1366px) and (min-width: 992px) and (max-height: 828px) {
	.logo-link {
		width: 250px;
	}

	.hero-rotator-section-kburns {
		padding-top: 120px;
	}

	.slide-caption-wrapper {
		max-width: 55%;
	}
}
/* ADJUST HEADER PADDING, LOGO SIZE AND NAV ON NARROWER DESKTOPS */
@media screen and (max-width: 1277px) and (min-width: 1170px) {
	.hero-rotator-section-kburns {
		padding-top: 120px;
	}

	.main-nav-link {
		font-size: 1rem;
		padding-left: 0.55rem;
		padding-right: 0.55rem;
	}

	.logo-link {
		width: 250px;
	}

	.header-section {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.inside-page-wrapper {
		padding-top: 114px;
	}
}

@media screen and (max-width: 1169px) and (min-width: 1050px) {
	.hero-rotator-section-kburns {
		padding-top: 114px;
	}

	.main-nav-link {
		font-size: 0.95rem;
		padding-left: 0.35rem;
		padding-right: 0.35rem;
	}

	.logo-link {
		width: 235px;
	}

	.header-section {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.inside-page-wrapper {
		padding-top: 114px;
	}
}

@media screen and (max-width: 1149px) and (min-width: 1060px) {
	.hero-rotator-section-kburns {
		padding-top: 114px;
	}

	.main-nav-link {
		font-size: 0.95rem;
		padding-left: 0.25rem;
		padding-right: 0.25rem;
	}

	.logo-link {
		width: 225px;
	}

	.inside-page-wrapper {
		padding-top: 114px;
	}
}

@media screen and (max-width: 1059px) and (min-width: 992px) {
	.hero-rotator-section-kburns {
		padding-top: 114px;
	}

	.main-nav-link {
		font-size: 0.9rem;
		padding-left: 0.25rem;
		padding-right: 0.25rem;
	}

	.logo-link {
		width: 225px;
	}

	.inside-page-wrapper {
		padding-top: 114px;
	}

	.header-section {
		padding-right: 1rem;
		padding-left: 1rem;
	}
}

/****************** Tablesaw ***********************/
/****************************************************/
@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child,
	.tablesaw.styled tr:last-child td:last-child {
		border-bottom: none;
	}

	.tablesaw-stack td .tablesaw-cell-label,
	.tablesaw-stack th .tablesaw-cell-label {
		word-break: break-word;
	}
}

/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and ( min-width: 991px ) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and ( min-width: 767px ) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/****************** Print ***********************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/*********************** Header **********************/
/*****************************************************/
.search-button {
	background-color: transparent;
	border: 0px;
	text-indent: -9999px;
	cursor: pointer;
	padding: 0px;
	margin: 0px;
}

.header-section a,
.header-section a:hover {
	box-shadow: none;
}

.header-search-form fieldset div,
.mobile-search-form fieldset div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.mobile-search-form fieldset {
	width: 100%;
}

.header-search-wrap {
	display: flex;
}

.schedule-online-btn {
	background-color: #467ba9;
	padding: 10px 15px !important;
	transition: all 0.3s cubic-bezier(0.842, 0.007, 0.772, 1.001);
	text-align: center;
	font-family: 'Work Sans', sans-serif;
	color: #fff;
	font-weight: 500;
	font-size: 1rem;
	line-height: 1rem;
	margin-right: 22px;
	display: inline-block;
}

.schedule-online-btn:hover {
	color: #05436d;
	background-color: #b3d236;
}

.schedule-online-btn.desktop {
	display: inline-block;
}

.schedule-online-container.mobile {
	display: none;
	background-color: #075488;
	padding: 3px;
	height: 42px;
	margin-top: -8px;
}

.schedule-online-container.mobile .schedule-online-btn {
	width: 100%;
}

@media (max-width: 991px) {
	.schedule-online-btn.desktop {
		display: none;
	}

	.schedule-online-container.mobile {
		display: block;
		text-align: center;
	}
}

/****************** Mobile Menu **********************/
/*****************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
}

.mobile-navigation-menu.mm-menu {
	background: #fff;
}

.mobile-navigation-menu a,
.mobile-navigation-menu a:hover {
	box-shadow: none;
}

.mobile-navigation-menu.mm-menu li a {
	padding: 1rem;
	-webkit-transition-duration: 500ms;
	transition-duration: 500ms;
	font-family: 'Work Sans', sans-serif;
	color: #075488;
	font-size: 1.05rem;
	line-height: 1.33rem;
	font-weight: 500;
	text-align: center;
	letter-spacing: -0.02rem;
	text-decoration: none;
	text-transform: uppercase;
	text-align: left;
	border-bottom: 0px;
}

.mm-listitem:after {
	left: 0px ;
	border-bottom: 1px solid #bbbdc0;
}

.mm-listitem .mm-btn_next:before {
	border-color: #bbbdc0;
}

.mobile-navigation-menu.mm-menu li.active {
	background-image: url("/20170713122722/assets/images/vdmc-square-green.svg");
	background-position: 0px 50%;
	background-size: 18px 18px;
	background-repeat: no-repeat;
	color: #075488;
}

.mobile-navigation-menu.mm-menu li.active a {
	padding-left: 2rem;
}

.mm-menu .mm-listitem_selected > a:not(.mm-btn_next),
.mm-menu .mm-listitem_selected > span {
	background-color: transparent;
}

.mm-menu .mm-listview .mm-btn_next:after {
	border-color: #075488;
}

.mm-menu .mm-navbar a,
.mm-menu .mm-navbar > * {
	font-family: 'Work Sans', sans-serif;
	color: #075488;
	font-size: 1.05rem;
	line-height: 1.33rem;
	font-weight: 500;
	text-align: left;
	text-transform: uppercase;
	padding-top: 16px;
}

.mm-menu .mm-navbar {
	height: 50px;
	border-color: #bbbdc0;
}

.mm-panels > .mm-panel > .mm-navbar + .mm-listview {
	margin-top: -10px;
}

.mm-btn_prev:before {
	left: 18px;
	top: 12px;
}


.mm-menu .mm-btn:before {
	border-color: #075488;
}

/****************** Accesible Menu *******************/
/*****************************************************/
.accesible-navigation-menu ul {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
}

.accesible-navigation-menu li {
	display: inline;
	margin: 0px 0.25rem;
	padding: 0px;
}

.accesible-navigation-menu li.nav-drop-wrap .container-wrapper {
	opacity: 0;
	max-height: 0px;
	position: absolute;
	top: 100%;
	z-index: 100;
	min-width: 200px;
	left: 0;
	padding-top: 7px;
	transition: max-height 100ms ease 0s, opacity 400ms ease 0s;
	height: 0;
}

.accesible-navigation-menu li.nav-drop-wrap .container-wrapper ul {
	display: none;
}

.accesible-navigation-menu li.nav-drop-wrap.open .container-wrapper {
	opacity: 1;
	max-height: 500px;
	height: auto;
}

.accesible-navigation-menu li.nav-drop-wrap.open .container-wrapper ul {
	display: inline-block;
}

@media (-webkit-min-device-pixel-ratio: 0) {
	.accesible-navigation-menu li.nav-drop-wrap .container-wrapper {
		padding-top: 8px;
	}
}

@media (-webkit-min-device-pixel-ratio: 0) and (max-width: 1277px) and (min-width: 1170px) {
	.accesible-navigation-menu li.nav-drop-wrap .container-wrapper {
		padding-top: 7px;
	}
}

.accesible-navigation-menu .container-wrapper:before {
	background-image: url("/20170713122722/assets/images/bg-drop-down.jpg");
	background-size: 12px 12px;
	background-position: center top;
	background-repeat: no-repeat;
	content: "";
	width: 100%;
	height: 12px;
	display: block;
	position: relative;
	z-index: 100;
}

.accesible-navigation-menu .container-wrapper ul {
	background-color: #fff;
	box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.3);
	color: #737373;
	display: inline-block;
	width: 100%;
	margin-top: -7px;
}

.accesible-navigation-menu .container-wrapper ul li:first-child {
	border-top: 1px solid #b3d236;
}

.accesible-navigation-menu li.nav-dropdown.open .container-wrapper ul {
	opacity: 1;
}

.accesible-navigation-menu .container-wrapper ul li {
	margin: 0px;
	padding: 0px;
	display: inline-block;
	width: 100%;
}

.accesible-navigation-menu .container-wrapper ul li a {
	padding: 0.65em 1rem;
	font-family: 'Work Sans', sans-serif;
	color: #075488;
	font-size: 1rem;
	line-height: 1.375em;
	font-weight: 400;
	letter-spacing: -0.02rem;
	display: inline-block;
	width: 100%;
	text-decoration: none;
	text-align: left;
}

.accesible-navigation-menu .container-wrapper ul li a:hover,
.accesible-navigation-menu .container-wrapper ul li a.w--current {
	background-color: rgba(7, 84, 136, 0.2);
}

#menu-item-services .container-wrapper {
	left: -41px;
}

#menu-item-providers .container-wrapper {
	left: -37px;
}

#menu-item-patients .container-wrapper,
#menu-item-patients .main-nav-link.active:after,
#menu-item-patients .main-nav-link:hover:after {
	left: 2px;
}

#menu-item-news .container-wrapper {
	left: -17px;
}

#menu-item-about .container-wrapper {
	left: -43px;
}

#menu-item-contact .container-wrapper {
	left: -43px;
}

/******************** Homepage **********************/
/*****************************************************/
.container.quicklink-container a,
.container.quicklink-container a:hover {
	box-shadow: none;
}

.welcome-cb .content p {
	display: block;
	margin-bottom: 1.2rem;
	color: #313233;
	font-size: 1.15rem;
	line-height: 2rem;
	font-weight: 400;
}

.welcome-cb .content * {
	font-size: 1.15rem;
	line-height: 2rem;
}

.news-events-header a,
.news-events-header a:hover,
.home-subhead a,
.home-subhead a:hover,
.news-events-heade a,
.news-events-heade a:hover {
	font-weight: inherit;
	line-height: inherit;
	font-size: inherit;
	font-family: inherit;
	color: inherit;
	line-height: inherit;
	box-shadow: none;
}

.callouts-cb .callout-paragraph * {
	font-size: 0.9rem;
	line-height: 1.45rem;
}

.container.ken-burns-wrapper {
	display: none;
	width: 100%;
	z-index: 1;
	position: absolute;
	left: 0;
	top: 133px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%;
}

@media (max-width: 991px) {
	.home-section.row-3 {
		background-attachment: scroll, scroll, scroll;
	}
}

@media (max-width: 767px) {
	.homepage-featured-video + .container.ken-burns-wrapper {
		display: block;
		top: 132px;
	}

	.homepage-featured-video {
		margin-top: 400px;
	}
}

@media (max-width: 479px) {
	.homepage-featured-video {
		margin-top: 225px;
	}

	.homepage-featured-video + .container.ken-burns-wrapper {
		top: 70px;
	}
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.fixed-image,
	.home-section.row-3 {
		background-attachment: scroll;
	}
}

@supports (-ms-ime-align: auto) {
	.fixed-image,
	.home-section.row-3 {
		background-attachment: scroll;
	}
}

/***************** Default Slideshow *********************/
/**********************************************************/
.default-slideshow .slide-paragraph p {
	font-weight: inherit;
	line-height: inherit;
	font-family: inherit;
	color: inherit;
}

.hero-rotator-widget-kburns.w-slider.default-slideshow .slider-more {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	z-index: 100;
	box-shadow: none;
}

@media (min-width: 991px) {
	.default-slideshow .slide-caption-container {
		display: block;
	}

	.default-slideshow .slide-caption-wrapper {
		float: right;
	}
}


/***************** Slideshow Testimonials *****************/
/**********************************************************/
.testimonials-slideshow .testimonial-paragraph p {
	font-weight: inherit;
	line-height: inherit;
	font-family: inherit;
	color: inherit;
}

.testimonials-slideshow .testimonial-text-wrapper {
	position: relative;
}

.testimonials-slideshow .slider-more {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
}

/************************ Video Slideshow *****************/
/**********************************************************/
.homepage-featured-video .ken-burns-wrapper {
	position: relative;
	left: 0;
}

.homepage-featured-video .video-hero {
	padding-top: 56%;
	position: absolute;
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
	width: 100%;
	height: 100%;
}

.homepage-featured-video .video-hero .mp4-video {
	width: 100%;
	position: absolute;
	padding: 0;
	top: 0;
	left: 0;
	height: auto;
	min-height: 100%;
}

.homepage-featured-video .slide-color-overlay {
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: 100;
}

.homepage-featured-video .slide-caption-container {
	z-index: 200;
}

.homepage-featured-video .videoEmbedLink {
	width: 100%;
	position: absolute;
	padding: 0;
	top: 0;
	left: 0;
	height: 100%;
}

@media (min-width: 991px) {
	.homepage-featured-video .slide-caption-container {
		display: block;
	}

	.homepage-featured-video .slide-caption-wrapper {
		float: right;
	}
}

@media (max-width: 991px) {
	.homepage-featured-video .slide-caption-container {
		position: relative;
	}

	.homepage-featured-video .video-hero .mp4-video {
		min-height: auto;
	}
}

@media (max-width: 767px) {
	.homepage-featured-video .video-hero {
		padding-top: 400px;
	}

	.homepage-featured-video .ken-burns-wrapper {
		display: none;
	}

	.homepage-featured-video .ken-burns-slide-1,
	.homepage-featured-video .w-slider-mask,
	.homepage-featured-video .hero-rotator-widget-kburns {
		position: static;
	}

	.homepage-featured-video .slide-color-overlay {
		top: 0;
	}
}


/****************** Inside Page *******************/
/*************************************************/
.main-content *:first-child {
	margin-top: 0px;
}

.main-content {
	margin-top: 1.5rem;
}

.breadcrumbs-list li:last-child {
	border-right: 0px;
}

.breadcrumbs-list li:last-child a {
	border-bottom: 0px;
}

.breadcrumbs-list a,
.breadcrumbs-list a:hover {
	box-shadow: none;
	padding-bottom: 0px;
	font-weight: normal;
	font-family: 'Work Sans', sans-serif;
}

.nested-nav-list {
	background: #f5f5f5;
}

.left-nav-list-link,
.left-nav-list-link:hover {
	box-shadow: none;
	font-family: 'Work Sans', sans-serif;
	font-weight: normal;
}

.nested-nav-list .nested-nav-list {
	background: #e9e9e9;
}

.left-nav-list-link.active {
	padding-left: 0.85rem;
	background-color: transparent;
	background-image: url("/20170713122722/assets/images/chevron-right_vdmc-blue.svg");
	background-position: 0px 50%;
	background-size: 8px 8px;
	background-repeat: no-repeat;
}

.left-nav-list-link.active.has-children {
	background-color: #cfdde7;
	color: #075488;
	padding-left: 0.35rem;
	background-image: none;
}

.left-nav-list-link.active.has-children:hover {
	transform: none;
}


.left-nav-section-link,
.left-nav-section-link:hover {
	box-shadow: none;
	color: #075488;
}

.left-column .main-content-outer-wrap {
	width: calc(78% - 3.25vw);
}

.left-column .main-content-outer-wrap .main-content-inner-wrap {
	display: block;
}

.main-content-inner-wrap img {
	height: auto;
	max-width: 100%;
	vertical-align: middle;
	display: inline-block;
}

td img {
	max-width: none;
}

@media (max-width: 991px) {
	.left-column .main-content-outer-wrap {
		width: 100%;
	}
}

@media (max-width: 767px) {
	.mobile-friendly-image,
	.main-content-inner-wrap img.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}


/******************* Right Sidebar *******************/
/*****************************************************/
.right-side-col .box {
	margin-top: 1.5rem;
	margin-bottom: 1rem;
	padding-bottom: 0.75rem;
	border-bottom: 1px solid #d6d2c6;
	font-size: 0.85rem;
	line-height: 1.4rem;
}


.right-side-col .box h4,
.right-side-col .box h3 {
	margin-bottom: 0.25rem;
	font-family: 'Work Sans', sans-serif;
	font-size: 1.15rem;
	line-height: 1.55rem;
	font-weight: 500;
	text-transform: uppercase;
	color: #075488;
	margin-top: 0px;
}

.right-side-col .box p {
	margin-bottom: 0.5rem;
	font-size: 0.85rem;
	line-height: 1.4rem;
}

.right-side-col .box ul {
	font-size: 0.85rem;
	line-height: 1.4rem;
}


.right-side-col .box td {
	vertical-align: top;
	padding-bottom: 0.5rem;
}

.right-side-col .box .contentbox_item_image {
	width: 24px;
}

.right-side-col .box.documents td:first-child {
	padding-right: 7px;
}

.right-side-col .box.links p {
	margin-bottom: 0.25rem;
	margin-top: 0px;
}

.right-side-col .box.tools td:first-child img {
	margin-right: 5px;
}


/*********************** Footer **********************/
/*****************************************************/
.footer-container a,
.footer-container a:hover {
	box-shadow: none;
	padding-bottom: 0px;
}

.phone-paragraph .mobile {
	display: none;
	color: #fff;
	font-family: 'Work Sans', sans-serif;
	font-weight: 300;
}

.phone-paragraph .desktop {
	display: inline;
	text-decoration: none;
}

#GRIPFooterLogo {
	float: right;
	line-height: 11px;
	padding-top: 1rem !important;
}

#GRIPFooterLogo a {
	text-decoration: none;
	border: 0px;
	box-shadow: none;
}

.footer-nav-list-link.active {
	-webkit-transform: translate(5px, 0px);
	-ms-transform: translate(5px, 0px);
	transform: translate(5px, 0px);
	color: #b3d236;
}

@media (max-width: 991px) {
	.phone-paragraph .mobile {
		display: inline;
	}

	.phone-paragraph .desktop {
		display: none;
	}
}

@media (max-width: 767px) {
	#GRIPFooterLogo {
		float: none;
	}
}

/****************** Inside Page Styles ***************/
/*****************************************************/
body a {
	padding-bottom: 2px;
	box-shadow: inset 0 -1px 0 0 #bbbdc0;
	font-family: Merriweather, serif;
	color: #467ba9;
	font-weight: 700;
	text-decoration: none;
}

body a:hover {
	box-shadow: inset 0 -2px 0 0 #83a9c3;
	color: #1f5390;
}

h1 a,
h2 a {
	font-weight: 400;
}

h3 a,
h4 a,
h5 a,
h6 a {
	font-weight: 500;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	padding-bottom: 3px;
	box-shadow: inset 0 -1px 0 0 #bbbdc0;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #467ba9;
	text-decoration: none;
	border-bottom: 0px solid transparent;
}

h3 a,
h4 a,
h5 a,
h6 a {
	font-family: 'Work Sans', sans-serif;
}


h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
	padding-bottom: 3px;
	box-shadow: inset 0 -3px 0 0 #83a9c3;
	color: #1f5390;
}

hr {
	border-top: 0px !important;
	border-bottom: 1px solid #cecab7 !important;
	border-bottom-color: #cecab7;
}

/* buttons */
.inside-body-section .button,
.primary,
.inside-body-section button[type="submit"],
.inside-body-section .cms_form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
button[type=submit] {
	padding: 0.38rem 0.75rem;
	border: 2px solid #075488;
	background-color: #075488;
	box-shadow: -1px 4px 8px 0 rgba(0, 0, 0, 0.35);
	font-family: 'Work Sans', sans-serif;
	color: #fff;
	font-size: 1.05rem;
	line-height: 1.55rem;
	font-weight: 500;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	margin-top: 1rem;
	margin-right: 1.5rem;
	margin-bottom: 0.5rem;
}

.primary:hover,
.inside-body-section button[type="submit"]:hover,
.inside-body-section .cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
button[type=submit]:hover {
	border-color: #05436d;
	background-color: #05436d;
	color: #fff;
	box-shadow: -2px 9px 15px 2px rgba(0, 0, 0, 0.2), 0 0 8px 0 rgba(0, 0, 0, 0.45);
}

.secondary,
.inside-body-section button[type="submit"].secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.inside-body-section .cms_form_button.secondary,
.inside-body-section .button.secondary {
	border: 2px solid #b3d236;
	background-color: #b3d236;
	color: #05436d;
	padding: 0.35rem 0.75rem;
	box-shadow: -1px 4px 8px 0 rgba(0, 0, 0, 0.35);
	font-family: 'Work Sans', sans-serif;
	font-size: 1.05rem;
	line-height: 1.55rem;
	font-weight: 500;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	margin-top: 1rem;
	margin-right: 1.5rem;
	margin-bottom: 0.5rem;
}

.secondary:hover,
.inside-body-section button[type="submit"].secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.inside-body-section .cms_form_button.secondary:hover,
.inside-body-section .button.secondary:hover {
	border-color: #9fbb2d;
	color: #05436d;
	background-color: #9fbb2d;
	box-shadow: -2px 9px 15px 2px rgba(0, 0, 0, 0.2), 0 0 8px 0 rgba(0, 0, 0, 0.45);
}

.tertiary,
.inside-body-section button[type="submit"].tertiary,
.cms_form_button.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	border: 2px solid #b3d236;
	background-color: #b3d236;
	color: #05436d;
	padding: 0.35rem 0.75rem;
	box-shadow: -1px 4px 8px 0 rgba(0, 0, 0, 0.35);
	font-family: 'Work Sans', sans-serif;
	font-size: 1.05rem;
	line-height: 1.55rem;
	font-weight: 500;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	margin-top: 1rem;
	margin-right: 1.5rem;
	margin-bottom: 0.5rem;
}

.tertiary:hover,
.inside-body-section button[type="submit"].tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover {
	border-color: #9fbb2d;
	color: #05436d;
	background-color: #9fbb2d;
	box-shadow: -2px 9px 15px 2px rgba(0, 0, 0, 0.2), 0 0 8px 0 rgba(0, 0, 0, 0.45);
}

/* messages */
#message.success {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	border-left: 6px solid #11b91e;
	background-color: rgba(17, 185, 30, 0.25);
	font-weight: 500;
	font-size: 1.1rem;
	font-family: 'Work Sans', sans-serif;
}

#message.error {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	border-left: 6px solid #c70616;
	background-color: rgba(253, 54, 54, 0.15);
	font-weight: 500;
	font-size: 1.1rem;
	font-family: 'Work Sans', sans-serif;
}

#message.success *,
#message.error * {
	font-weight: 500;
	font-size: 1.1rem;
	font-family: 'Work Sans', sans-serif;
}

#message.error p:last-child,
#message.success p:last-child {
	margin-bottom: 0;
}

/* tables */
table.styled {
	background-color: white;
	border-spacing: 0px;
	font-family: 'Work Sans', sans-serif;
}

table.styled th,
table.styled td {
	font-family: 'Work Sans', sans-serif;
	border-right: 1px solid #075488;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: 500;
	font-size: 1.1rem;
	line-height: 1.4rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #075488;
	color: #ffffff;
}

table.styled th {
	font-weight: 500;
	font-size: 1.1rem;
	line-height: 1.4rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #8bb4cf;
	color: #000000;
}

table.styled th a,
table.styled th a:visited {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom: none;
	font-family: 'Work Sans', sans-serif;
	box-shadow: none;
}

table.styled th a:hover {
	color: inherit;
	font-weight: inherit;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 1rem;
	line-height: 1.4rem;
	color: #000000;
}

table.styled.striped tr:nth-child(even) {
	background: #ddd;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* forms */
input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
input[type="search"],
input[type="file"],
select,
textarea {
	padding: 8px 12px;
	margin-bottom: 10px;
	font-size: 0.875rem;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
}

textarea {
	resize: vertical;
}

label {
	font-weight: 400;
}

@media (max-width: 991px) {
	input[type="text"],
	input[type="tel"],
	input[type="password"],
	input[type="email"],
	input[type="search"],
	input[type="file"],
	select,
	textarea {
		width: 100%;
	}
}

/*blockquotes*/
blockquote {
	position: relative;
}

blockquote p {
	margin-top: 0;
	margin-bottom: 0;
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
}

blockquote:after {
	position: absolute;
	left: -10px;
	width: 16px;
	height: 16px;
	background-color: #075488;
	content: "";
	top: 47%;
}

/*pagination*/
.pagination-wrapper {
	font-size: 0.9rem;
}

.pagination-wrapper .pagination-previous-page .disabled,
.pagination-wrapper .pagination-next-page .disabled {
	color: #707070 !important;
	font-family: inherit;
}


/******************** Modules **********************/
/***************************************************/
/* Search */
.search-collection .search-node {
	margin: 1.2rem 0px 0px 0px;
	padding: 1.5rem 0px 0 0px;
	border-top: 1px solid #cecab7;
	border-bottom: 0px;
	display: inline-block;
	width: 100%;
}

.search-collection .search-node a {
	line-height: 1.5rem;
}

/* Staff */
#staffdirectory_module.cms_list .cms_list_item .image {
	padding-right: 15px;
	width: 150px;
}

#staffdirectory_module.cms_list .cms_list_item .image a img {
	max-width: 140px;
}

#staffdirectory_module.cms_list .cms_item_divider {
	border-top: 1px solid #cecab7;
}

@media (max-width: 991px) {
	#staffdirectory_module.cms_list .cms_list_item .image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}

	#staffdirectory_module.cms_entity .image {
		float: none;
		margin-right: 0px;
		display: block;
	}
}


/* Accounts */
#account_module.form .form_item .label,
#account_module.form .form_item.required .label {
	font-family: 'Work Sans', sans-serif;
	font-weight: 500;
	color: #313233;
	font-size: 1rem;
	line-height: 1.65rem;
}

#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 256px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid #e9ece3;
	margin: 20px 0;
	padding-bottom: 7px;
	color: #225e7c;
	font-size: 1.1rem;
	line-height: 1.3em;
	font-weight: 500;
	font-family: 'Open Sans', sans-serif;
}

#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #ee0000;
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url("/core/public/shared/assets/images/required.gif");
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .form_item .field .login_field {
		max-width: 100%;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

/* Events */
#events_module.cms_list .cms_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid #cecab7;
}

#fullcalendar-controls #categories .category-wrapper .category {
	top: 0px;
}

#events_module.cms_list .cms_list_item .cms_title {
	width: 100%;
}

#events_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#events_module.cms_list .cms_list_item .event-category-label {
	display: inline-block;
	margin-right: 4px;
}

#events_module.cms_form .cms_label,
#events_module.cms_form .cms_label label {
	font-family: 'Work Sans', sans-serif;
	font-weight: 500;
	color: #313233;
	font-size: 1rem;
	line-height: 1.65rem;
}

#events_module.cms_form .cms_label,
#events_module.cms_form .cms_field,
#events_module.cms_form .cms_required,
#events_module.cms_form .cms_non_required,
#events_module.cms_form .cms_td_answers {
	background-color: transparent;
	vertical-align: top;
}

#events_module.cms_form .cms_required {
	background-position: center 10px;
}

#events_module.cms_form h3 {
	margin-bottom: 0px;
}

#events_module.cms_form .cms_divider {
	margin-top: 5px !important;
	padding-top: 1px !important;
}

@media (max-width: 991px) {
	#events_module.cms_form .cms_field input[type="text"],
	#events_module.cms_form .cms_field input[type="password"],
	#events_module.cms_form .cms_field select,
	#events_module.cms_form .cms_field textarea,
	#events_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#events_module.cms_form .cms_label {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
	}

	#events_module.cms_form .cms_required,
	#events_module.cms_form .cms_non_required {
		width: 20px;
		display: inline;
		text-align: left;
		padding-left: 0px;
		float: left;
	}
}


/* faqs */
#faqs_module .cms_title h3 {
	margin-top: 0px;
}

#faqs_module .cms_list_item ul {
	padding-left: 0px;
	margin: 0px;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}


/*jobs */
#jobs_module.cms_list .cms_list_item {
	margin-top: 0px;
}

#jobs_module.cms_form label {
	font-family: 'Work Sans', sans-serif;
	font-weight: 500;
	color: #313233;
	font-size: 1rem;
	line-height: 1.65rem;
}

#jobs_module.cms_list .cms_list_item .cms_title h3,
#jobs_module.cms_list .cms_list_item .cms_date h3 {
	margin-top: 0px;
}

#jobs_module.cms_list .cms_list_item .cms_date h3 {
	font-size: 0.9rem;
	font-family: Merriweather, serif;
	color: #313233;
	line-height: 0.9rem;
}

#jobs_module.cms_list .cms_list_item .cms_footer {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-color: #cecab7;
}

#jobs_module.cms_form .cms_label,
#jobs_module.cms_form .cms_field,
#jobs_module.cms_form .cms_required {
	background: none;
	font-weight: normal !important;
	vertical-align: top;
}

#jobs_module.cms_form .cms_divider {
	display: none;
}

#jobs_module.cms_form .cms_form_button {
	padding: 0.38rem 0.75rem;
	margin-top: 1rem;
}

@media (max-width: 991px) {
	#jobs_module.cms_form .cms_field input[type="text"],
	#jobs_module.cms_form .cms_field input[type="tel"],
	#jobs_module.cms_form .cms_field input[type="email"],
	#jobs_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#jobs_module.cms_form .cms_label,
	#jobs_module.cms_form .cms_required {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
		padding-top: 7px;
	}

	#jobs_module.cms_form .cms_required {
		width: 20px;
	}
}


/* news */
#news_module.cms_list article time {
	font-weight: 500;
	font-family: 'Work Sans', sans-serif;
}

#news_module.cms_entity .cms_date h3 {
	margin-top: 0px;
}

#news_module.cms_list .cms_categories_row .image {
	margin-bottom: 10px;
}

.news_module.cms_category_list .cms_category_header_image {
	margin-bottom: 10px;
}

.box.archive .archive_links {
	overflow: hidden;
}

#news_module.cms_list .cms_item_divider,
#news_module.cms_entity .cms_item_divider {
	margin-top: 1.8rem;
	margin-bottom: 0.8rem;
	border-top: 1px solid #cecab7;
}

@media (max-width: 767px) {
	#news_module.cms_entity .cms_content .image {
		display: block;
		float: none;
		margin-left: 0px;
		height: auto;
		margin-bottom: 15px;
		margin-top: 15px;
	}
}

/* partners */
#partners_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0rem;
}

#partners_module.cms_list .cms_list_item table td.image {
	padding-right: 25px;
}

#partners_module.cms_list .cms_list_item table td.image a img {
	max-width: 150px;
}

#partners_module.cms_entity .image {
	float: none;
	margin-right: 0px;
}

#partners_module.cms_list .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid #cecab7;
}

@media (max-width: 991px) {
	#partners_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#partners_module.cms_list .cms_list_item table td.image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}


/* photo albums */
#photoalbums_module.cms_list .cms_list_item table td.cms_image {
	padding-right: 15px;
}

#photoalbums_module.cms_list .cms_list_item table td.cms_image a img {
	max-width: 150px;
}

#photoalbums_module.cms_list .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid #cecab7;
}

@media (max-width: 991px) {
	#photoalbums_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#photoalbums_module.cms_list .cms_list_item table td.cms_image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/* resources */

/* testimonials */
#testimonials_module.cms_list .cms_item_divider,
#testimonials_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid #cecab7;
}

#testimonials_module.cms_list .cms_list_item .cms_bottom,
#testimonials_module.cms_list .cms_list_item .cms_content {
	display: inline-block;
	width: 100%;
}

#testimonials_module.cms_list .cms_list_item .cms_author_name {
	font-weight: 500;
	font-size: 0.9rem;
}

#testimonials_module.cms_list .cms_list_item .testimonial-link,
#testimonials_module.cms_list .cms_list_item .testimonial-link:hover {
	box-shadow: none;
}

/* video */
#video_module.cms_list .cms_list_item {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-bottom: 1px solid #cecab7;
}

/* Submission form */
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 0px;
	font-size: 0.9rem;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	margin: 0px;
	font-size: 0.9rem;
	line-height: normal;
}

#submissionforms_module.cms_form .cms_required em {
	color: #ee0000;
}

#submissionforms_module.cms_form .required_information em {
	color: #ee0000;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}


#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

#submissionforms_module.cms_form .cms_field.currency:before,
.responsive #submissionforms_module.cms_form .cms_field.currency:before {
	top: 40%;
}

#submissionforms_module.cms_form .formBreak,
.module-forms .formBreak {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-bottom: 0px;
	border-top: 1px solid #cecab7;
}

#submissionforms_module.cms_form #form-payment-info .ui-form-field-payment.ui-form-field-subtitle span {
	margin-top: 0;
	margin-bottom: 1rem;
	font-family: 'Work Sans', sans-serif;
	color: #075488;
	font-size: 1.4rem;
	line-height: 2.2rem;
	font-weight: 500;
}

#submissionforms_module.cms_form #form-payment-info em {
	color: #ee0000;
	font-size: 1rem;
	line-height: 1rem;
	font-family: Merriweather, serif;
	font-style: normal;
}

#submissionforms_module.cms_form #form-payment-info .ui-form-field-payment .ui-form-label {
	padding-top: 0px;
}

#submissionforms_module.cms_form #form-payment-info .ui-form-input.ui-form-radio label {
	display: inline;
}

#submissionforms_module.cms_form .cms_label label {
	font-family: 'Work Sans', sans-serif;
	font-weight: 500;
	color: #313233;
	font-size: 1rem;
	line-height: 1.65rem;
}

@media (max-width: 991px) {
	#submissionforms_module.cms_form .cms_field.currency:before,
	.responsive #submissionforms_module.cms_form .cms_field.currency:before {
		top: 40%;
	}

	#submissionforms_module.cms_form #form-payment-info .ui-form-fields {
		padding: 0px;
	}

	#submissionforms_module.cms_form #form-payment-info .ui-form-label {
		height: auto;
		left: 0;
		line-height: 1rem;
		padding: 6px 6px 6px 0;
		position: static;
		text-align: left;
		top: 0;
		width: 100%;
	}

	#submissionforms_module.cms_form #form-payment-info .ui-form-select > select,
	#submissionforms_module.cms_form #form-payment-info .ui-form-text > input[ type="text" ] {
		max-width: 100%;
	}


	#submissionforms_module.cms_form #form-payment-info .ui-form-panel {
		padding: 0px !important;
	}
}

/* mailing lists */
#mailinglists-module.form .form_subheading {
	border-bottom: 1px solid #cecab7;
	margin: 12px 0 20px;
}

#mailinglists-module.form .form_subheading h2 {
	margin-top: 0px;
	margin-bottom: 0px;
}

#mailinglists-module.form div:nth-child(2) {
	display: none;
}

/**************** Medical Modules ******************/
/***************************************************/
/* search form */
.physician-search-accordion .title-trigger-box,
.physician-search-skipper {
	box-shadow: none;
}

.physician-search-accordion .physician-search-field .form-reset {
	color: #467ba9;
	cursor: pointer;
}

.physician-search-accordion .physician-search-field.by-name .bottom-search-submit:hover {
	background-color: #00853e;
	cursor: pointer;
}

.physician-search-accordion .form-reveal-wrap {
	max-height: none !important;
}

.physician-search-accordion .physician-search-field.by-name .bottom-search-submit {
	width: 15%;
}

.physician-search-accordion .physician-search-field.button-section-wrap .top-search-submit {
	margin-top: 2rem;
}

#physicians-module.advanced-search .physicians-advanced-search-form-wrap.name-search-wrap .select2-container--default .select2-selection--single {
	border: 1px solid #cccccc;
	margin-bottom: 10px;
}

.physician-search-accordion .physician-search-field label {
	font-family: 'Work Sans', sans-serif;
	font-weight: 500;
	color: #313233;
	font-size: 1rem;
	line-height: 1.65rem;
}

#physicians-module.advanced-search .form-field-label.physicians-advanced-search-label {
	font-family: 'Work Sans', sans-serif;
	font-weight: 500;
	color: #313233;
	font-size: 1rem;
	line-height: 1.65rem;
}

#physicians-module .md-patient-comments-wrap .md-row.md-patient-comment-block {
	background-color: #efefef;
	font-size: 0.875em;
	line-height: 1.45em;
	color: #313233;
}

#physicians-module .physician-profile-section .video-in-content-wrapper {
	text-align: center;
	margin-top: 25px;
}

@media (max-width: 767px) {
	.physician-search-accordion .physician-search-field.by-name .bottom-search-submit {
		margin: 2rem 0px 1rem;
		float: none;
		width: 100% !important;
	}

	.physician-search-accordion .physician-search-field.by-name .select2-container {
		width: 100% !important;
	}
}

/* clinics */
#clinics-module.clinics-collection .md-row .with-bottom-divider {
	border-bottom: 1px solid #cecab7;
	margin-bottom: 1rem;
	padding-bottom: 0.5rem;
}

/* specialties */

/* greeding cards */
#greetingcards-module .greeting-card-form-preview-wrapper .GreetingCardGeneralInfo .ui-form-fieldset .ui-form-field input,
#greetingcards-module .greeting-card-form-preview-wrapper .GreetingCardGeneralInfo .ui-form-fieldset .ui-form-field textarea {
	max-width: 100%;
}

#greetingcards-module .GreetingCardGeneralInfo .ui-form-fieldset .ui-form-field em {
	color: #ee0000 !important;
}

/*physicians */
#physicians-module.physicians-collection .node.physician-node {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-bottom: 1px solid #cecab7;
}

#physicians-module.physicians-collection .node.physician-node .physician-image {
	text-align: left;
}

#physicians-module .md-custom-heading.md-big-heading {
	font-size: 1.2rem;
	line-height: 1.2em;
	margin-right: 10px;
}

#physicians-module.node .physician-profile-section,
#physicians-module .md-row.doctor-profile-section {
	margin-top: 1.5rem !important;
	margin-bottom: 1.5rem !important;
	border-top: 1px solid #cecab7 !important;
}

@media screen and (max-width: 767px) {
	#physicians-module .md-column-content-wrap {
		padding-right: 20px;
	}
}

/* babies */
#babies-module.babies-collection .baby-node .description p:nth-child(2) {
	font-weight: 500;
	font-family: 'Work Sans', sans-serif;
}

#babies-module.babies-collection .baby-node .description p:nth-child(2) strong {
	font-weight: 500;
}



/***************** Content Boxes *******************/
/***************************************************/
/* news */
#news_module.homepage_contentbox .cms_list_item {
	margin-bottom: 1rem;
	padding-bottom: 0.5rem;
	border-bottom: 1px solid #bbbdc0;
}

#news_module.homepage_contentbox .cms_list_item .cms_title h3 {
	margin: 0px 0px 0.25rem 0px;
	font-family: Merriweather, serif;
	color: #467ba9;
	font-weight: 700;
	text-decoration: none;
	font-size: 1.25rem;
	line-height: 1.75rem;
}

#news_module.homepage_contentbox .cms_list_item .cms_title h3 a {
	padding-bottom: 2px;
	box-shadow: inset 0 -1px 0 0 #bbbdc0;
	font-family: Merriweather, serif;
	color: #467ba9;
	font-weight: 700;
	text-decoration: none;
	font-size: 1.25rem;
	line-height: 1.75rem;
	letter-spacing: 0rem;
	text-transform: none;
}

#news_module.homepage_contentbox .cms_list_item .cms_title h3 a:hover {
	border-bottom-color: transparent;
	box-shadow: inset 0 -2px 0 0 #83a9c3;
	color: #274c77;
}

#news_module.homepage_contentbox .cms_list_item .cms_date h3 {
	display: inline-block;
	margin-bottom: 0.5rem;
	font-family: 'Work Sans', sans-serif;
	font-weight: 500;
	text-transform: uppercase;
	color: #313233;
	font-size: 1rem;
	line-height: 1.65rem;
	margin-top: 0px;
}

#news_module.homepage_contentbox.displaymode_list > a:last-child {
	display: none;
}

/* events */
#events_module.homepage_contentbox .feed-paragraph {
	margin-bottom: 0px;
	display: inline-block;
	width: 100%;
}

#events_module.homepage_contentbox .news-events-title-link:hover {
	padding-bottom: 2px;
}

/* partners */

/* testimoniasl */
#testimonials_module_contentbox.contentbox_item .author-name,
#testimonials_module_contentbox.contentbox_item .company {
	font-weight: bold;
}

/* videos */
.homepage-featured-video iframe {
	max-width: 100%;
	height: calc(100% + 120px);
	top: -60px;
	border: 0px;
}

/* form */
#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #ccc;
	font-size: 0.9rem;
	margin-bottom: 10px;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .sigWrapper .pad {
	max-width: 100%;
}

#submissionforms_module.homepage_contentbox .cms_hint,
#submissionforms_module.homepage_contentbox .cms_date_hint {
	color: #313233;
	font-family: inherit;
}

@media (min-width: 991px) {
	.slider-section #submissionforms_module.homepage_contentbox table td,
	.slider-section #submissionforms_module.homepage_contentbox table a,
	.slider-section #submissionforms_module.homepage_contentbox table a:hover,
	.slider-section #submissionforms_module.homepage_contentbox .cms_hint,
	.slider-section #submissionforms_module.homepage_contentbox .cms_date_hint {
		color: #fff;
		font-weight: normal;
	}
}


/* Varification Modal */
#acceptance-modal.modal {
	display: none; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 1500; /* Sit on top */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgb(0, 0, 0); /* Fallback color */
	background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
#acceptance-modal .modal-content {
	background-color: #fefefe;
	margin: 10% auto; /* 15% from the top and centered */
	padding: 20px;
	border: 1px solid #888;
	width: 90%; /* Could be more or less, depending on screen size */
	max-width: 1300px;
}

/* The Close Button */
#acceptance-modal .close-acceptance-modal-container {
	display: block;
	width: 100%;
	text-align: right;
}

#acceptance-modal .close-acceptance-modal {
	color: #aaa;
	font-size: 28px;
	font-weight: bold;
}

#acceptance-modal .close-acceptance-modal:hover,
#acceptance-modal .close-acceptance-modal:focus {
	color: black;
	text-decoration: none;
	cursor: pointer;
}

/* Alert Box */
.alert-wrap {
	text-align: center;
	background-color: #b4d044;
	padding: 20px 10px;
	font-weight: bold;
	color: #095485;
	position: relative;
	z-index: 1000;
	box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.5);
	margin-top: 32px;
}

.alert-wrap p,
.alert-wrap h1,
.alert-wrap h2,
.alert-wrap h3,
.alert-wrap h4,
.alert-wrap h5,
.alert-wrap h6,
.alert-wrap li,
.alert-wrap td,
.alert-wrap span {
	color: #095485;
}

.alert-wrap p {
	font-size: 1.2rem;
}

.alert-wrap p:last-child {
	margin-bottom: 0px;
}

.alert-wrap a {
	box-shadow: none;
	border-bottom: 0px;
	color: #095485;
	text-decoration: underline;
}

.alert-wrap a.button {
	height: auto;
}

.alert-wrap a:hover {
	box-shadow: none;
	border-bottom: 0px;
	color: #095485;
	text-decoration: none;
}

@media screen and (max-width: 1366px) {
	.alert-wrap {
		margin-top: 0px;
	}
}

@media screen and (max-width: 1366px) and (min-width: 1277px) and (min-height: 828px) {
	.alert-wrap {
		margin-top: 32px;
	}
}
